/**
 * Global JS functions.
 *
 * TODO this file needs to be split into modules/domains.
 */

class I18N {
    lookup = {};
    t(str) {
        return this.lookup[str] || str;
    }
}

globalThis.I18N = I18N;

export const MP = {
    init: function () {
        // Called on dom ready, handy list + makes debugging easier
        this.com.zoom();
        this.com.sortable();
    },

    /* JS for modules */
    mod: {
    },

    /* Generic components (used across MP modules */
    com: {
        zoom: function () {
            MpClick('.zoom-js', function(imgEl) {
                imgEl.classList.toggle('zooming-js');
            });

            MP.util.watch('.zoom-js', function(imgEl) {
                MP.facade.init_zoom_image(imgEl);
            });

            // handle zoom
            document.addEventListener('mousemove', function(e) {
                if (!e || !e.target || !e.target.classList || !e.target.classList.contains('zooming-js')) {
                    return; // not a zoomable image
                }
                if (e.target.naturalWidth === e.target.width && e.target.naturalHeight === e.target.height) {
                    return; // image is already full size; skip
                }

                var zoomer = e.target.parentNode;
                var offsetX = e.offsetX || 0;
                var offsetY = e.offsetY || 0;
                var x = offsetX/zoomer.offsetWidth*100;
                var y = offsetY/zoomer.offsetHeight*100;
                zoomer.style.backgroundPosition = x + '% ' + y + '%';
            });
        },

        sortable: function () {
            // .sortable ol is the default sorter, try to use it combined with the hidden input.
            // Set IDs via id="sortitem-1" or data-id="1" (data-id is preferred).
            // /traject/beheerder/article/forms/elements/{articleId}/{versionId}
            // todo/improvement: add sortable-js to PHP and remove .sortable selectors from JS
            MP.util.watch('.sortable ol, .sortable ul, .sortable-js ol, .sortable-js ul', function (el) {
                // jQuery UI sortable
                j$(el).sortable({
                    scroll: false,
                    stop: function() {
                        MP.facade.sort_finish();
                    },
                    connectWith: '.sortable-js ol, .sortable-js ul' // Allows to drag between multiple lists
                });
            });
        }
    },

    /* Utilities */
    util: {
        // Todo: search project: forEach.*\n.*addEventListener and replace with MpClick
        // Delegate click events
        // We don't have to be aware of the DOM state.
        // And we don't have to add event listeners to every element anymore.
        // E.g. : MpClick('.myclass', function(el, event) {
        //     console.log(el); // 'selector' element (so not always the clicked element)
        //     console.log(event); // click event (the actual event, containg the clicked target)
        // });
        click: function(selector, callback) {
            document.addEventListener('click', function (event) {
                // Get clicked element and check if any parent matches selector
                var currentEl = event.target;
                while (currentEl) {
                    if (currentEl.matches(selector)) {
                        // do not follow hyperlinks
                        if (currentEl.tagName === 'A') {
                            event.preventDefault();
                        }

                        return callback(currentEl, event);
                    }
                    currentEl = currentEl.parentElement;
                }

                return false;
            });
        },
        change: function(selector, callback) {
            document.addEventListener('change', function (event) {
                if (!event.target.matches(selector)) {
                    return false;
                }

                return callback(event);
            });
        },
        // Solves issues related to new elements added to the DOM, but when a MpClick not fulfills
        // So is it possible to trigger a function by a click? Then use MpClick
        watch: function (selector, callback) {
            // wait till DOM is loaded
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', function () {
                    MP.util.watch(selector, callback);
                });
                return;
            }

            MP.facade.observer_handler(selector, callback);
            var observer = new MutationObserver(function(mutations) {
                MP.facade.observer_handler(selector, callback);
            });
            observer.observe(document.body, {childList: true, subtree: true});
        },
    },

    /* Facade, don't use these functions directly */
    facade: {
        observer_handler(selector, callback) {
            var items = document.querySelectorAll(selector);
            items.forEach(function (item) {
                // if item already observed: continue
                if (item.dataset.mpObserved === 'true') {
                    return;
                }
                item.dataset.mpObserved = 'true';
                callback(item);
            });
        },
        init_zoom_image: function (imgEl)    {
            var parent = imgEl.parentElement;
            if (parent && parent.tagName === 'FIGURE') {
                return; // Already wrapped
            }
            if (imgEl.naturalWidth <= imgEl.width && imgEl.naturalHeight <= imgEl.height) {
                return; // image is full size; skip
            }

            // create figure
            var figure = document.createElement('figure');
            figure.classList.add('zoomable-js');
            figure.classList.add('com_zoomable');
            figure.style.maxHeight = 'inherit';
            figure.style.backgroundImage = 'url(' + imgEl.src + ')';

            // attach image to figure and figure to parent el
            imgEl.replaceWith(figure);
            figure.appendChild(imgEl);
        },
        sort_finish: function () {
            // Update sortables including sub-sortables
            // clear all hidden inputs, so removed elements are also removed from the list
            j$('.sortable-js ol, .sortable-js ul, .sortable ul, .sortable ol').find('input[type="hidden"]').val('');
            j$('.sortable-js ol, .sortable-js ul, .sortable ul, .sortable ol').each(function (i, el) {
                if (!el.querySelector(':scope > li')) { // scope = current El
                    return; // empty list
                }

                // check how ids are passed (id or data-id)?
                var attribute = el.querySelector(':scope > li').getAttribute('id') ? 'id' : 'data-id';
                // get value like: 3|5|9
                // var val = j$(subEl).sortable('toArray', {attribute: attribute}).join('|').replace(/sortitem-/g, ''); nativejs:
                var val = Array.from(el.querySelectorAll(':scope > li')).map(function (li) {
                    return li.getAttribute(attribute).replace('sortitem-', '');
                }).join('|');

                // fill next hidden input
                var input = smartNext(el, 'input[type="hidden"]');
                if (input) {
                    input.value = val;
                }
            });
        },
    }
};
document.addEventListener('DOMContentLoaded', function () {
    MP.init();
});

globalThis.MpClick = function (selector, callback) {
    return MP.util.click(selector, callback);
};

globalThis.MpChange = function(selector, callback) {
    return MP.util.change(selector, callback);
};

// Basic cookie functions
if (!window.Cookie) {
    class Cookie {
        static read(key) {
            var value = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
            return value ? value.pop() : '';
        }

        static write(name, value, days) {
            if (value === '') {
                document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                return;
            }
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "") + expires + "; path=/";
        }
    }
    window.Cookie = Cookie;
}


export function forEach(obj, callback) {
    for (const key of Object.keys(obj)) {
        callback(key, obj[key]);
    }
}

// Simple replacement for Mootools Request.
// Pass url, options, and onComplete in constructor.
// You can replace anything in the GET or POST methods by passing an object.
// Example usage:
// var req = new MpRequest('/url', {key: 'value'}, function(response) {
//     console.log(response);
// }).get().abort().post({data: {new: 'post_data'}, url: 'newUrl'}) // whatever
// .get({data: {key: 'value'}}) // will be posted as query string
// .post({data: {key: 'value'}}) // will be posted as FORM data
// .post() with not data will post the first <form> on the page
// .postDataAsBody().post() will post data as JSON
// .htmlResponse().get() will handle the response data as HTML (text)
// idea: refactor to function and return object with methods (Then we can do 'MpRequest' instead of 'new MpRequest')
export class MpRequest {
    constructor(url, data, onComplete) {
        if (typeof data !== 'object') {
            onComplete = data;
            data = undefined;
        }
        this.options = {};
        this.options.data = data;
        this.options.url = url;
        this.options.onComplete = onComplete;
        this.options.postDataAsBody = false; // post data as form data or JSON body

        this.xhr = new XMLHttpRequest();
        this.xhr.open('GET', this.options.url);

        var cThis = this; // remember this for onload scope
        this.xhr.onload = function() {
            if (cThis.xhr.status !== 200) {
                console.warn('Request failed.  Returned status of ' + cThis.xhr.status);
                return;
            }

            if (cThis.options.onComplete) {
                var data = cThis.xhr.response;
                if (typeof data === 'string' && (data.startsWith('[') || data.startsWith('{'))) {
                    data = JSON.parse(data);
                }

                cThis.options.onComplete(data, cThis.options.context ?? null);
            }
        };

    }

    get(options) {
        this.options = Object.assign({}, this.options, options); // merge options

        var queryString = '';
        if (this.options.data !== '') { // todo: move to custom function, so we don't have to nest if
            if (this.options.data && !(this.options.data instanceof HTMLFormElement)) { // Object, e.g. {ajax: 'true', key: 'value'}
                var concatOrStart = this.options.url.indexOf('?') === -1 ? '?' : '&'; // Append if url already has query string
                queryString = concatOrStart + new URLSearchParams(this.options.data).toString();
            } else if (typeof this.options.data !== 'undefined') { // Form element
                var items = this.options.data?.querySelectorAll('input, select, textarea') ?? [];
                items.forEach(function (item) {
                    if (item.type === 'checkbox' || item.type === 'radio') {
                        if (item.checked) {
                            queryString += '&' + item.name + '=' + item.value;
                        }
                    } else {
                        queryString += '&' + item.name + '=' + item.value;
                    }
                });
            }
        }
        this.xhr.open('GET', this.options.url + queryString);

        this.xhr.responseType = this.options.responseType || 'json';
        this.xhr.setRequestHeader('Accept', 'application/json');
        this.xhr.setRequestHeader('X-Request', 'JSON');
        this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        this.xhr.send();

        return this;
    }

    post(options) {
        this.options = Object.assign({}, this.options, options); // merge options
        this.xhr.open('POST', this.options.url);

        this.xhr.responseType = this.options.responseType || 'json';
        this.xhr.setRequestHeader('Accept', 'application/json');
        this.xhr.setRequestHeader('X-Request', 'JSON');
        this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        if (this.options.postDataAsBody === true) {
            this.xhr.send(this.options.data ? JSON.stringify(this.options.data) : null);
        } else {
            this.xhr.send(MpRequest.getFormData(this.options.data));
        }

        return this;
    }

    abort() {
        this.xhr.abort();
        return this;
    }

    htmlResponse() {
        this.options.responseType = 'text';
        return this;
    }

    postDataAsBody() {
        this.options.postDataAsBody = true;
        return this;
    }

    // Get form data as FormData object.
    // Pass an object or form element.
    static getFormData(data) {
        if (!data) {
            data = document.querySelector('form'); // get first form on page
        }
        if (data instanceof HTMLFormElement) {
            return new FormData(data);
        }

        var formData = new FormData();
        // custom post data, e.g. {data: {key: 'value'}}
        forEach(data, function(key, value) {
            // if object is value:
            if (typeof value === 'object') {
                formData.append(key, MpRequest.getFormData(value));
            } else {
                formData.append(key, value);
            }
        });

        return formData;
    }
}

// Polyfill for Array.includes in IE11. 97.71% coverage
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
        enumerable: false,
        writable: true,
        value: function (searchElement /*, fromIndex*/) {
            'use strict';
            var O = Object(this);
            var len = parseInt(O.length) || 0;
            if (len === 0) {
                return false;
            }
            var n = parseInt(arguments[1]) || 0;
            var k;
            if (n >= 0) {
                k = n;
            } else {
                k = len + n;
                if (k < 0) {
                    k = 0;
                }
            }
            var currentElement;
            while (k < len) {
                currentElement = O[k];
                if (searchElement === currentElement ||
                    (searchElement !== searchElement && currentElement !== currentElement)) { // NaN !== NaN
                    return true;
                }
                k++;
            }
            return false;
        }
    });
}

j$(function () {
    j$('a.fakelink.ajax').click(function (e) {
        e.stopPropagation();
        return false;
    });

    colorInput();
    if (j$('#toTop')) {
        j$(window).scroll(function () {
            if (j$(this).scrollTop()) {
                j$('#toTop').fadeIn();
            } else {
                j$('#toTop').fadeOut();
            }
        });
        j$("#toTop").click(function () {
            j$("html, body").animate({scrollTop: 0}, 1000);
        });
    }

    j$('.articleform .frmrow input[type=radio]').each(function () {
        if (j$(this).prop('checked')) {
            j$(this).parent().parent().data('checked', j$(this).val());
        }
        j$(this).click(function() {
            if (j$(this).parent().parent().data('checked') === j$(this).val()) {
                j$(this).parent().parent().data('checked', '');
                j$(this).prop('checked', false);
            } else {
                j$(this).parent().parent().data('checked', j$(this).val());
            }
        });
    });
});

export function colorInput() {
    j$('input[type=color]').change(function () {
        j$(this).next().val(j$(this).val());
    });
    j$('.color-hex').change(function () {
        var color = '#' + j$(this).val();
        if (color.substr(0, 2) === '##') {
            color = color.substr(1);
        }
        if (color.length === 4) {
            color = color.substr(1);
            color = '#' + color + color;
        }
        if (color.length === 7) {
            j$(this).prev().val(color);
        }
    });
}

export function qualificationSelect(data) {
    if (data.loading) {
        return data.text;
    }
    if (data.children) {
        return "<strong>" + data.text + "</strong>";
    }
    return "<div class='select2-result-repository clearfix'>" + (data.obj.language ? (data.obj.language.indexOf('flag flag-') === -1 ? "<span class='flag flag-" + data.obj.language.substring(3, 5).toLowerCase() + "'></span>" : "<span class='" + data.obj.language.toLowerCase() + "'></span>"): '') + data.obj.text + (data.obj.code ? "<br /><span class='smalltext'>" + $I18N.t('Code:') + " " + data.obj.code + " </span>" : '') + '</div>';
}

export function userSelect(data) {
    if (data.loading) {
        return data.text;
    }
    if (data.children) {
        return "<strong>" + data.text + "</strong>";
    }
    return "<div class='select2-result-repository clearfix" + (data.obj.status === 'inactive' || data.obj.status === 'archived' ? ' inact' : '' ) +"'>" + data.obj.fullname + (data.obj.code ? "<br /><span class='smalltext'>" + $I18N.t('Code:') + " " + data.obj.code + " </span>" : '') + '</div>';
}

export function moduleSelect(data) {
    if (data.loading) {
        return data.text;
    }
    if (data.children) {
        return "<strong>" + data.title + "</strong>";
    }
    return "<div class='select2-result-repository clearfix'>" + (data.obj.language ? (data.obj.language.indexOf('flag flag-') === -1 ? "<span class='flag flag-" + data.obj.language.substring(3, 5).toLowerCase() + "'></span>" : "<span class='" + data.obj.language.toLowerCase() + "'></span>"): '') + data.obj.title + (data.obj.code ? "<br /><span class='smalltext'>" + $I18N.t('Code:') + " " + data.obj.code + " </span>" : '') + '</div>';
}

export function iconSelect(data) {
    if (data.loading) {
        return data.text;
    }
    if (data.children) {
        return "<strong>" + data.text + "</strong>";
    }
    if (data.obj) {
        return "<div class='select2-result-repository clearfix'>" + data.obj.icon + ' ' + data.obj.title + "</div>";
    } else if (data.id && Number.isInteger(parseInt(data.id))) {
        return "<div class='select2-result-repository clearfix'>" + data.text + ' ' + data.title + "</div>";
    } else {
        return "<div class='select2-result-repository clearfix'><span class='mr5 icon-" + data.text + "'></span>" + data.text + "</div>";
    }
}

export function remindotest(data) {
    if (data.loading) {
        return data.text;
    }
    if (data.children) {
        return "<strong>" + data.text + "</strong>";
    }
    return "<div class='select2-result-repository clearfix'>" + data.obj.text + (data.obj.study_name ? "<br /><span class='smalltext'>" + data.obj.study_name + " </span>" : '') + '</div>';
}

export function articleSelect(data) {
    return moduleSelect(data);
}

export function editionSelect(data) {
    return moduleSelect(data);
}

export function usergroupSelect(data) {
    if (data.loading) {
        return data.text;
    }
    if (data.children) {
        return "<strong>" + data.text + "</strong>";
    }
    return "<div class='select2-result-repository clearfix'>" + data.obj.name + (data.obj.category ? "<br /><span class='smalltext'>" + $I18N.t('Category:') + " " + data.obj.category + " </span></div>" : '');
}

export function managerSwitchSelect(data) {
    if (data.loading) {
        return data.text;
    }
    if (data.children) {
        return "<strong>" + data.text + "</strong>";
    }
    let userlabels = '';
    if (data.obj && data.obj.userlabels) {
        let i = 0;
        while (i < data.obj.userlabels.length) {
            if (i > 0) {
                userlabels += ', ';
            }

            userlabels += "<span style='color:" + data.obj.userlabels[i].color + ";'>&#" + data.obj.userlabels[i].ascii + ";</span>" + data.obj.userlabels[i].shortTitle;
            i++;
        }
    }
    if ('' !== userlabels) {
        userlabels = ' (' + userlabels + ')';
    }
    if (data.obj) {
        return data.obj.fullname_rev + userlabels;
    }
    return data.text;
}

// todo: figure out, can we replace this with a native JS solution?
(function initTooltips() {
    j$(document).ready(function () {

        var defaultTooltipsterSettings = {
            contentAsHTML: true,
            theme: 'tooltipster-mp',
            animation: 'fade',
            updateAnimation: null,
            delay: 0,
            maxWidth: 600,
            trigger: 'custom',
            multiple: true,
            triggerOpen: {
                click: true,
                tap: true,
                mouseenter: true,
                touchstart: true
            },
            triggerClose: {
                click: true,
                tap: true,
                mouseleave: true,
                originClick: true,
                touchleave: true
            }
        };

        var tooltipsterSettings = j$.extend(
            true,
            {},
            defaultTooltipsterSettings,
            {
                triggerClose: {
                    scroll: true
                },
                functionBefore: function (instance, helper) {
                    var j$origin = j$(helper.origin);
                    if (j$origin.data('loaded') !== true) {
                        instance.option('side', j$(helper.origin).data('content-tooltip-side') || 'top');
                        var content = instance.content() || j$(helper.origin).data('content-tooltip-text') || '';

                        content = content.replace(/</g, '&lt;');
                        content = content.replace(/>/g, '&gt;');
                        content = content.replace(/\n- ([^\n]*)/g, '<li>$1</li>');
                        content = content.replace(/(<li>.*<\/li>)+\n?/g, '<ul class="bulleted">$1</ul>');
                        content = content.replace(/\*([^\*]*)\*/g, '<strong>$1</strong>');
                        content = content.replace(/\/\/([^\/]*)\/\//g, '<i>$1</i>');
                        content = content.replace(/\n/g, '<br />');

                        if (content === '') {
                            content = null;
                        }
                        instance.content(content);

                        j$origin.data('loaded', true);
                    }
                }
            }
        );

        var tooltipsterAjaxSettings = j$.extend(
            true,
            {},
            defaultTooltipsterSettings,
            {
                delay: [0, 200],
                interactive: true,
                minWidth: 400,
                triggerOpen: {
                    mouseenter: false
                },
                // todo: translate
                content: 'Bezig met laden...',
                functionBefore: function (instance, helper) {
                    var j$origin = j$(helper.origin);
                    if (j$origin.data('ajax-loaded') !== true) {
                        j$.get(j$(helper.origin).data('tooltip-html'), function (data) {
                            instance.content(data);
                            j$origin.data('ajax-loaded', true);
                        });
                    }
                }
            }
        );

        var tooltipsterHtmlSettings = j$.extend(
            true,
            {},
            defaultTooltipsterSettings,
            {
                interactive: true,
                triggerOpen: {
                    click: true,
                    tap: true,
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose: {
                    click: true,
                    tap: true,
                    mouseleave: true,
                    originClick: true,
                    touchleave: true
                },
                contentAsHTML: true,
                delay: [0, 200],
                functionBefore: function (instance, helper) {
                    var j$origin = j$(helper.origin);
                    if (j$origin.data('loaded') !== true) {
                        instance.option('side', j$(helper.origin).data('content-tooltip-side') || 'top');
                        var tooltipHtml = j$(helper.origin).data('content-tooltip-html') || null;
                        if (null === tooltipHtml) { //get html from ref-element
                            var tooltipHtmlSrc = j$(helper.origin).data('content-tooltip-ref') || null;
                            if (tooltipHtmlSrc != null && j$(tooltipHtmlSrc)) {
                                tooltipHtml = j$(tooltipHtmlSrc).html() || null;
                            }
                        }
                        if (null === tooltipHtml) { //get html from own element
                            tooltipHtml = j$(helper.origin).html() || null;
                        }
                        instance.content(tooltipHtml);
                        j$origin.data('loaded', true);
                    }
                }
            }
        );

        j$('.tooltip, .content-tooltip').tooltipster(tooltipsterSettings);
        j$('.content-footnotetooltip, .tooltiphtml').tooltipster(tooltipsterHtmlSettings);
        j$('body').on('mouseenter', '.tooltip:not(.tooltipstered), .content-tooltip:not(.tooltipstered)', function () {
            j$(this).tooltipster(tooltipsterSettings);
            j$(this).tooltipster('open');
        });
        j$('body').on('mouseenter', '.content-footnotetooltip:not(.tooltipstered), .tooltiphtml:not(.tooltipstered)', function () {
            j$(this).tooltipster(tooltipsterHtmlSettings);
            j$(this).tooltipster('open');
        });
        j$('.ajaxtt').tooltipster(tooltipsterAjaxSettings);
    });
})();

var formpost = false;

// Not a real popup, but a new window. Used once? Make inline?
export function popup(url, w, h, scroll) {
    var l = (screen.width - w) / 2;
    var t = (screen.height - h) / 2;

    window.open(url, '_blank', 'resizable=yes,location=no,menubar=no,scrollbars=' + scroll + ',status=no,toolbar=no,fullscreen=no,dependent=no,width=' + w + ',height=' + h + ',left=' + l + ',top=' + t);
}

// Used twice, refactor to MpClick
export function check_radios(container) {
    container.querySelectorAll('input[type="radio"]').forEach(function (radio) {
        if (false != radio.target) {
            radio.target.style.display = (radio.checked ? 'block' : 'none');
        }
    });
}

// Used once, make inline
export function isCrossOriginFrame() {
    try {
        return (!window.top.location.hostname);
    } catch (e) {
        return true;
    }
}

// Notifications widget / module (shown under menus for accompanying users)
document.addEventListener('DOMContentLoaded', function () {
    MpClick('#notifications a.daysel, #notifications .item a', function (el) {
        new MpRequest(el.href, function (response) {
            if (response.output) {
                document.getElementById('notification_content').innerHTML = response.output;
            }

            document.querySelector('#notifications .daysel.active').classList.remove('active');
            document.querySelector('#daysel' + response.daysel).classList.add('active');
        }).get();
    });
    // trigger click event for active daysel
    document.querySelector('#notifications .daysel.active')?.click();
});

/* List of functions that need to be called after content is loaded */
document.addEventListener('DOMContentLoaded', function () {
    /*
     * On mobile: hamburger menu
     */
    var navToggler = j$('#nav-toggler');
    navToggler.on('click', function () {
        addResponsiveNav(); // runs only once
        setTimeout(function () {
            j$('#nav-responsive').addClass('nav-responsive-visible');
        }, 100);
    });

    function addResponsiveNav() {
        if (j$('#nav-responsive').length > 0) {
            return; // already added
        }
        // create responsive nav el
        j$('body').append('<div id="nav-responsive"><div class="nav-responsive-data"></div></div>');
        // clone current nav
        j$('#nav').clone().appendTo('.nav-responsive-data').removeAttr('id').addClass('nav-responsive-nav').find('ul').removeAttr('id');
        // close button
        j$('.nav-responsive-data').append('<span class="nav-responsive-close clickable fas fa-times"></span>');
        // close button event
        j$('.nav-responsive-close').on('click', function () {
            j$('#nav-responsive').removeClass('nav-responsive-visible');
        });
    }

    /*
     * Viewing article with sidebar (e.g. see DB table: article_idea)
     */
    if (document.getElementById('contentdata')) {
        var sidebarBoxes = document.getElementById('contentdata').getElementsByClassName('article-sidebar-box');
        if (sidebarBoxes.length > 0) {
            var responsiveSidebar = document.createElement('div');
            responsiveSidebar.setAttribute('id', 'sidebar-responsive');
            document.body.appendChild(responsiveSidebar);
            for (var i = 0; i < sidebarBoxes.length; ++i) {
                var sidebarBox = sidebarBoxes[i].cloneNode(true);

                var togglerIconData = sidebarBox.getAttribute('data-icon-data') || '';
                var togglerIcon = sidebarBox.getAttribute('data-icon') || 'fa-plus-circle'

                var sidebarToggler = document.createElement('span');
                if (togglerIconData === '') {
                    sidebarToggler.classList.add(togglerIcon, 'sidebarbox-toggler');
                } else {
                    var iconData = JSON.parse(togglerIconData);
                    sidebarToggler.classList.add('sidebarbox-toggler');

                    for (var j = 0; j < iconData.class.length; j++) {
                        sidebarToggler.classList.add(iconData.class[j]);
                    }
                    sidebarToggler.style.backgroundColor = 'transparent';
                    for (var key in iconData.style) {
                        if (key === 'color') {
                            sidebarToggler.style.color = iconData.style[key];
                        }
                        if (key === 'background-color') {
                            sidebarToggler.style.backgroundColor = iconData.style[key];
                        }
                    }
                    var iconShape = document.createElement('span');
                    iconShape.classList.add(iconData.iconClass);
                    sidebarToggler.appendChild(iconShape);
                }

                sidebarToggler.addEventListener('click', function () {
                    if (this.parentNode) {
                        if (this.parentNode.classList.contains('sidebarbox-visible')) {
                            this.parentNode.classList.remove('sidebarbox-visible');
                        } else {
                            this.parentNode.classList.add('sidebarbox-visible');
                        }
                    }
                });

                sidebarBox.insertBefore(sidebarToggler, sidebarBox.firstChild);
                responsiveSidebar.appendChild(sidebarBox);
            }
        }
    }

    //instead of target='_blank'
    MpClick('a.newwindow', function (el) {
        if (el.rel && el.rel.match(/\d+,\d+/)) {
            popup(el.href, el.rel.split(',')[0], el.rel.split(',')[1], 'yes');
        } else {
            window.open(el.href, "_blank");
        }
    });

    contentEvents();

    _init_toggle();

    j$('.frmcheckboxcontainer input').on('click', function () {
        j$(this).parent().parent().children('span').removeClass('checked');
        j$(this).parent().addClass('checked');
    });

    var radioToggle = document.querySelectorAll('.radiotoggle');
    radioToggle.forEach(function (el) {
        el.querySelectorAll('input[type="radio"]').forEach(function (radio) {
            var div_id = 'div_' + radio.name + '_' + radio.value;
            var div = document.getElementById(div_id);
            radio.target = (radio.value != '' && div) ? div : false;
            radio.addEventListener('click', function () {
                check_radios(el);
            });
        });

        check_radios(el);
    });

    var toggleReverse = document.querySelectorAll('.toggle_reverse');
    toggleReverse.forEach(function (el) {
        el.toggler = el.querySelector('input[type="checkbox"]');
        el.target = smartNext(el, 'div');

        el.toggler.addEventListener('click', function () {
            el.target.style.display = (el.toggler.checked ? 'none' : 'block');
        });

        el.target.style.display = (el.toggler.checked ? 'none' : 'block');
    });

    // .groupblock not found in code base. Code can be removed? Including _duplicate and _renumber_block?
    var groupBlock = document.querySelectorAll('.groupblock');
    groupBlock.forEach(function (block) {
        block.button = block.querySelector('.addmore');
        block.counter = block.querySelector('.elcounter');
        block.template = block.querySelector('.formgroup').clone();

        block.template.classList.remove('first');

        block.button.addEventListener('click', function () {
            var newgroup = _duplicate(block);
            newgroup.querySelector('.removethis').addEventListener('click', function () {
                block.counter.value--;
                newgroup.remove();
                _renumber_block(block);
            });

            block.querySelector('.groupbottom').grab(newgroup, 'before'); // Mootools
            block.counter.value++;
        });

        block.querySelectorAll('.formgroup').forEach(function (existing) {
            if (existing.querySelector('.removethis')) {
                existing.querySelector('.removethis').addEventListener('click', function () {
                    block.counter.value--;
                    existing.remove();
                    _renumber_block(block);
                });
            }

        });
    });

    disableFields();

    var prefill = document.querySelectorAll('.prefill');
    prefill.forEach(function (el) {
        el.addEventListener('click', function (e) {
            if (document.getElementById(el.getAttribute('rel')).value.length > 0) {
                // todo: translate
                if (!confirm('Weet u zeker dat u de inhoud van het tekstveld wilt overschrijven?')) {
                    e.preventDefault();
                    return;
                }
            }
            var txt = this.dataset.title.replace(/<br \/>/g, '\n');
            txt = txt.replace(/&lt;/g, '<');
            txt = txt.replace(/&gt;/g, '>');
            document.getElementById(el.getAttribute('rel')).value = txt;
            e.preventDefault();
        });
    });


    j$('input.checkbox_toggle').on('click', function () {
        var ids = [];
        if (this.type == 'radio') {
            var elements = this.closest('form').querySelectorAll('input[name="' + this.name + '"]');
            elements.forEach(function (el) {
                ids.push(el.id);
            });
        } else {
            ids.push(this.id);
        }

        ids.forEach(function (id) {
            var sliders = document.querySelectorAll('div.' + id);
            var input = document.getElementById(id);
            sliders.forEach(function (slider) {
                if (input.checked) {
                    slider.classList.add('selected');
                } else {
                    slider.classList.remove('selected');
                }

                var max = parseInt(slider.style.maxHeight);

                if (!max) {
                    max = slider.scrollHeight;
                }
                if (slider.classList.contains('toggle')) {
                    if (input.checked) {
                        slideDown(slider);
                    } else {
                        slideUp(slider);
                    }
                } else {
                    slider.style.height = (input.checked ? Math.max(slider.scrollHeight, max) : 0) + 'px';
                }
            }, this);
        }, this);
    });

    var slider = document.querySelectorAll('.slider');

    // Deelnemer, e.g. in https://portaal-youfitretail-nl.mijnportfolio.test/traject/deelnemer/profiel/edit/
    var checkboxHideUnusedTitles = document.querySelectorAll('.checkbox_hide_unusedtitles');
    checkboxHideUnusedTitles.forEach(function (div) {
        // When interests have subitems (title contains |), then show them grouped.
        if (div.classList.contains('subcat')) {
            var prev_group = false;
            var group_div = false;

            div.querySelectorAll('div.selecttable').forEach(function (table) {
                table.classList.add('h');

                var titleEl = table.previousElementSibling;
                var title = titleEl.innerText;
                var split = title.split('|');
                titleEl.innerHTML = split[1];

                if (prev_group !== split[0]) {
                    prev_group = split[0];
                    var group_title = document.createElement('h3');
                    group_title.textContent = split[0];
                    titleEl.parentNode.insertBefore(group_title, titleEl);

                    group_title.addEventListener('click', function (e) {
                        var next = this.nextElementSibling;
                        next.classList.toggle('h0');
                        if (next.classList.contains('h0')) {
                            next.querySelectorAll('input').forEach(function (checkbox) {
                                checkbox.checked = false;
                            });
                        }
                    });
                }
                if (group_div) {
                    group_div.appendChild(titleEl);
                }

                var isused = false;
                table.querySelectorAll('input').forEach(function (checkbox) {
                    if (checkbox.checked) {
                        isused = true;
                        group_div.classList.remove('h0');
                    }
                });

                if (!isused) {
                    table.classList.add('h0');
                }

                titleEl.addEventListener('click', function (e) {
                    var table= this.nextElementSibling;
                    if (table.classList.contains('h0')) {
                        table.classList.remove('h0');
                        table.querySelectorAll('input').forEach(function (checkbox) {
                            checkbox.checked = true;
                        });
                    } else {
                        table.classList.add('h0');
                        table.querySelectorAll('input').forEach(function (checkbox) {
                            checkbox.checked = false;
                        });
                    }
                });
            });

            div.classList.remove('h0');
        } else {
            div.querySelectorAll('div.selecttable').forEach(function (table) {
                table.classList.add('h');
                var title = j$(table).prev('h4')[0];
                var isused = false;
                table.querySelectorAll('input').forEach(function (checkbox) {
                    if (checkbox.checked) {
                        isused = true;
                    }
                });

                if (!isused) {
                    table.classList.add('h0');
                }

                title.addEventListener('click', function (e) {
                    var table = this.nextElementSibling;
                    table.classList.toggle('h0', !table.classList.contains('h0'));
                });
            });
        }
    });

    toggle_select();
    toggleDivs();
});


MpClick('span.copytoclipboard', function (el) {
    let copy = el.dataset.copy ? el.dataset.copy : el.textContent;
    if (typeof copy === 'object') {
        copy = JSON.stringify(copy);
    }
    navigator.clipboard.writeText(copy);

    // idea: call showMessage($I18N.t('Copied to clipboard'), 'success');
});

// Refactor to MpClick, then remove function
export function toggleDivs() {
    j$('.toggle_div').each(function () {
        j$(this).unbind('click');
        var container = j$('div#toggle_' + j$.escapeSelector(j$(this).data('toggle')));
        if (container.length === 0) {
            container = j$('div#toggle_' + j$.escapeSelector(j$(this).attr('id')));
        }
        if (container) {
            j$(this).click(function () {
                if (!container.hasClass('active')) {
                    slideDown(container);
                } else {
                    slideUp(container);
                }
            });
        }
    });
}

// Refactor to MpClick, then remove function
export function disableFields() {
    var disableFld = document.querySelectorAll('.disablefld');
    disableFld.forEach(function (el) {
        if (el.classList.contains('hidefld') && document.getElementById(el.id + 'fld')) {
            el.addEventListener('click', function (e) {
                if (this.checked) {
                    slideUp(document.getElementById(el.id + 'fld'));
                } else {
                    slideDown(document.getElementById(el.id + 'fld'));
                }
            });
        } else {
            el.addEventListener('click', function (e) {
                var fld = this.id.replace(/_disable$/, '');
                if (this.form.elements[fld + '_year']) {
                    this.form.elements[fld + '_year'].disabled = this.checked;
                    this.form.elements[fld + '_year'].selectedIndex = this.form.elements[fld + '_year'].options.length - 1;
                }
                if (this.form.elements[fld + '_month']) {
                    this.form.elements[fld + '_month'].disabled = this.checked;
                    this.form.elements[fld + '_month'].selectedIndex = new Date().getMonth();
                }
                if (this.form.elements[fld + '_day']) {
                    this.form.elements[fld + '_day'].disabled = this.checked;
                    this.form.elements[fld + '_day'].selectedIndex = new Date().getDay();
                }
            });
        }
    });
}

// Font size switcher
MpClick('#fontsize_font_s, #fontsize_font_l', function (el, event) {
    var isSmallFont = el.id === 'fontsize_font_s';

    // set class fontsize-active on clicked element
    document.querySelector('.fontsize-active')?.classList.remove('fontsize-active');
    el.classList.add('fontsize-active');

    // write cookie
    Cookie.write('stylesheet_fontsize', isSmallFont ? 'normal' : 'large');

    // toggle content font-size
    document.getElementById('content').classList.toggle('fontsize-large', !isSmallFont);
});
// set active class based on cookie
document.addEventListener('DOMContentLoaded', function () {
    if (!Cookie.read('stylesheet_fontsize')) {
        Cookie.write('stylesheet_fontsize', 'normal');
    }

    var isLarge = Cookie.read('stylesheet_fontsize') === 'large';
    document.getElementById(isLarge ? 'fontsize_font_l' : 'fontsize_font_s')?.classList.add('fontsize-active');
    document.getElementById('content').classList.toggle('fontsize-large', isLarge);
});


// on init; if all checked; check the checkall checkbox
j$(document).ready(function () {
    j$('.checkall_target').each(function () {
        handleAllChecked(this);
    });
});
// check all checkboxes
j$(document).on('click', '.checkall', function () {
    var checked = j$(this).find('input[type=checkbox]').prop('checked');
    j$(this).find('label').html(checked ? $I18N.t('Deselect all') : $I18N.t('Select all'));
    j$(this).next('.checkall_target').find('input[type=checkbox]').prop('checked', checked);
});
// if all checked; check the checkall checkbox
j$(document).on('change', '.checkall_target input[type=checkbox]', function () {
    handleAllChecked(this.closest('.checkall_target'));
});
export function handleAllChecked(allCheckboxes) {
    if (allCheckboxes === null || allCheckboxes.previousElementSibling === null || allCheckboxes.previousElementSibling.querySelector('input[type=checkbox]') === null) {
        return;
    }

    var allSelected = allCheckboxes.querySelectorAll('input[type=checkbox]:checked').length === allCheckboxes.querySelectorAll('input[type=checkbox]').length;
    allCheckboxes.previousElementSibling.querySelector('input[type=checkbox]').checked = allSelected;
    //allCheckboxes.previousElementSibling.querySelector('label').textContent = allSelected ? $I18N.t('Deselect all') : $I18N.t('Select all');
}

// figure out; possible with css?
export function _renumber_block(block) {
    block.querySelectorAll('.formgroup').forEach(function (group, i) {
        group.querySelectorAll('input').forEach(function (el) {
            var nameparts = el.name.split('_');
            nameparts[3] = i;
            el.name = nameparts.join('_');
        });

        group.querySelectorAll('select').forEach(function (el) {
            var nameparts = el.name.split('_');
            nameparts[3] = i;
            el.name = nameparts.join('_');
        });
    });
}

/**
 * Refactor to something like:
 *     MpClick('label', function (el, event) {
 *         var p = el.closest('p');
 *         var clone = p.cloneNode(true);
 *         var elCount = document.querySelectorAll('p').length;
 *         var input = clone.querySelector('input, select, textarea');
 *         var name = input.getAttribute('name');
 *         input.setAttribute('name', 'clone' + elCount + name);
 *         input.value = '';
 *         var label = clone.querySelector('label');
 *         label.textContent = label.textContent + ' clone' + elCount;
 *         label.setAttribute('for', 'clone' + elCount + name);
 *         p.after(clone);
 *     });
 */
export function _duplicate(block) {
    var dupe = block.template.clone();
    var elcount = 1;

    dupe.querySelectorAll(".frmrow, .timerange").forEach(function (el) {
        el.classList.remove('error');
    });

    dupe.querySelectorAll("input[type='text'], textarea").forEach(function (el) {
        el.value = '';

        var newid = 'dupe' + '_' + elcount + '_' + block.counter.value;
        el.id = newid;

        var nameparts = el.name.split('_'); // also gets scan_4_2_0_2[key]

        nameparts[2] = block.counter.value;
        el.name = nameparts.join('_');

        var label = smartPrev(el, '.frmlabel');
        if (label) {
            label.for = newid;
        }

        elcount++;
    });

    //var prevname = '';
    dupe.querySelectorAll("input[type='radio']").forEach(function (el) {
        el.checked = false;

        var newid = 'dupe' + '_' + elcount + '_' + block.counter.value;
        el.id = newid;

        var nameparts = el.name.split('_');
        nameparts[2] = block.counter.value;
        el.name = nameparts.join('_');

        var label = smartNext(el, '.frmlabel');
        if (label) {
            label.for = newid;
        }

        elcount++;
    });

    dupe.querySelectorAll("input[type='checkbox']").forEach(function (el) {
        el.checked = false;

        el.id = 'dupe' + '_' + elcount + '_' + block.counter.value;

        var nameparts = el.name.split('_');
        nameparts[2] = block.counter.value;
        el.name = nameparts.join('_');

        elcount++;
    });

    dupe.querySelectorAll("select").forEach(function (el) {
        el.value = '';

        var newid = 'dupe' + '_' + elcount + '_' + block.counter.value;
        el.id = newid;

        var nameparts = el.name.split('_');
        nameparts[2] = block.counter.value;
        el.name = nameparts.join('_');

        var label = smartPrev(el, '.frmlabel');
        if (label) {
            label.for = newid;
        }

        el.querySelector('option').value = ''; // value got truncated

        elcount++;
    });

    return dupe;
}

// Showing el after .tooltip_extended as tooltip
// todo: use the default tooltip instead. (e.g. move content to title attribute)
j$(function () {
    j$(document).on('mousemove', '.tooltip_extended', function (e) {
        j$(this).next().css('top', (e.clientY + 20) + 'px');
        j$(this).next().css('left', (e.clientX + 20) + 'px');
    });
});


                // Remove functions???
                function testsel(qid, sel) {
                    var selel = document.getElementById('ql' + qid + '_q' + sel);
                    var unsel = document.getElementById('ql' + qid + '_q' + (sel == 2 ? 1 : 2));

                    if (selel) {
                        selel.classList.add('selected');
                    }
                    if (unsel) {
                        unsel.classList.remove('selected');
                    }
                }
                function testhover(el, hovering) {
                    var el = document.getElementById(el);
                    if (el) {
                        el.classList.toggle('hovered', hovering);
                    }
                }



/* todo: refactor to MpChange, so we can remove function calls: toggle_select */
export function toggle_select() {
    var toggleSelect = document.querySelectorAll('.toggle_select');
    toggleSelect.forEach(function (el) {
        el.addEventListener('change', function (evt) {
            var toggle = el.open_toggle ?? false;
            if (toggle !== false) {
                var toggleToggle = document.getElementById('toggle_' + toggle);
                if (toggleToggle.classList.contains('toggle')) {
                    slideUp(toggleToggle);
                } else {
                    toggleToggle.style.display = 'none';
                }
                el.open_toggle = false;
            }

            var opt = this.options[this.selectedIndex];
            if (opt && opt.classList.contains('toggle_div')) {
                for (let i = 0; i < this.length; i++) {
                    let tmpOpt = this.options[i];
                    if (tmpOpt.classList.contains('toggle_div')) {
                        let tmpToggle = document.getElementById('toggle_' + tmpOpt.value);
                        if (tmpToggle) {
                            slideUp(tmpToggle);
                        }
                    }
                }
                var val = opt.value;
                var tog_el = document.getElementById('toggle_' + val);
                if (tog_el && (tog_el.style.display == "none" || !tog_el.classList.contains('active'))) {
                    if (tog_el.classList.contains('toggle')) {
                        slideDown(tog_el);
                    } else {
                        tog_el.style.display = 'block';
                    }
                    var select_elem = tog_el.getElementsByTagName('select');
                    for (var i = 0; i < select_elem.length; i++) {
                        select_elem[i].style.width = 'auto';
                    }
                    el.open_toggle = val;
                }
            } else {
                for (let i = 0; i < this.length; i++) {
                    let currentOpt = document.getElementById(this.options[i]);
                    if (currentOpt && currentOpt.classList.contains('toggle_div')) {
                        let currentToggle = document.getElementById('toggle_' + currentOpt.value);

                        if (currentToggle.classList.contains('toggle')) {
                            slideUp(currentToggle);
                        } else {
                            currentToggle.style.display = 'none';
                        }
                        el.open_toggle = currentOpt.value;
                    }
                }
            }
        });
    });
}

// Indented code can be removed
            function destroy_overlay() {
                if (document.getElementById('overlay_content')) {
                    document.getElementById('overlay_content').remove();
                }
                j$('#overlay').fadeOut();
            }

            // same method as next, but here with GET
            function submit_overlay() {
                document.getElementById('overlay_content').style.display = 'none';
                var form = document.querySelector('#overlay_content .form');

                new MpRequest(form.action, form.toQueryString().trim(), function () {
                    window.location.reload(true);
                }).get();
            }
            // same method, but now with POST
            function submitOverlay() {
                document.getElementById('overlay_content').style.display = 'none';
                var form = document.querySelector('#overlay_content .form');

                new MpRequest(form.action, form.toQueryString().trim() , function () {
                    window.location.reload(true);
                }).post();
            }

            // Class is not used. We ha
            class Overlay {
                options = {
                    id: 'overlay',
                    color: '#000',
                    duration: 500,
                    opacity: 0.5,
                    zIndex: 20000/*,
                    onClick: function(){},
                    onClose: function(){},
                    onHide: function(){},
                    onOpen: function(){},
                    onShow: function(){}
                    */
                };

                constructor (container, options) {
                    this.options = Object.assign({}, this.options, options); // merge options
                    this.container = document.getElementById(container);
                }

                build() {
                    this.overlay = document.createElement('div');
                    this.overlay.id = this.options.id;
                    this.overlay.style.position = 'fixed';
                    this.overlay.style.background = this.options.color;
                    this.overlay.style.left = 0;
                    this.overlay.style.top = 0;
                    this.overlay.style.zIndex = this.options.zIndex;
                    this.overlay.style.opacity = 0;
                    this.container += this.overlay;

                    return this;
                }

                open() {
                    this.fireEvent('open');
                    this.tween.start(this.options.opacity);
                    return this;
                }
                close() {
                    this.fireEvent('close');
                    this.tween.start(0);
                    return this;
                }
            }
// Remove till here


j$(document).on('click', '#root-overlay, #overlay_content #annuleren, #popup-close', function (e) {
    j$('#root-overlay').remove();
    j$('#overlay_content').remove();
});

j$(document).on('click', '#overlay_content #submit', function () {
    var form = document.querySelector('#overlay_content form');
    var method = form.getAttribute('method') || 'get';
    new MpRequest(form.action, form, function () {
        window.location.reload();
    })[method]();
});

export function addOverlayHtml() {
    // overlay already exists
    if (document.getElementById('root-overlay') && document.getElementById('overlay_content')) {
        return;
    }

    var rootOverlay = document.createElement('div');
    rootOverlay.id = 'root-overlay';
    var overlayContent = document.createElement('div');
    overlayContent.id = 'overlay_content';
    document.body.appendChild(rootOverlay);
    document.body.appendChild(overlayContent);

}

/* Switch project for root users */
// todo: make more intuitive; ajax call for listing. Select first on enter. Open on ?ctrl-ctrl? click.
export function sbSwitch() {
    var sb = j$('#sbcontainer');
    if (sb) {
        var bg;
        var sbwidth = sb.width();

        var closeSbSwicher = function (e) {
            e.preventDefault();
            sb.animate({ "right": -sbwidth }, 300, "easeOutCubic" , function() {
                j$("#sbcontent>div.active").removeClass("active");
                sb.css("right", "");
            });
            if (bg) {
                bg.removeClass('backdrop-active');
                setTimeout(function() { bg.remove() }, 300);
            }
        };

        var renderProjects = function () {
            var allProjects = j$('#psallprojects');
            if (!allProjects.data('loaded')) {
                projectSwitcherProjects.forEach(function (project, key) {
                    var li = j$('<li></li>');
                    li.attr('id', 'projectswitch-' + project.id);
                    li.appendTo(allProjects);
                    var link = j$('<a></a>');
                    link.attr('href', project.url);
                    link.text(project.name);
                    link.appendTo(li);
                    var image = j$('<img src="">');
                    image.attr('src', project.favicon);
                    image.addClass('favicon');
                    image.prependTo(link);

                    projectSwitcherProjects[key].name = project.name.toLowerCase();
                });
                allProjects.data('loaded', true);
            }

            var keywords = j$('#pskeywords').val().toLowerCase().split(' ').filter(Boolean);
            var emptyKeywords = keywords.length === 0;
            projectSwitcherProjects.forEach(function (project) {
                var showItem = true;
                if (!emptyKeywords) {
                    for (var k = 0; k < keywords.length; k++) {
                        if ((project.name + project.server_name).indexOf(keywords[k]) < 0) {
                            showItem = false;
                            break;
                        }
                    }
                }
                j$('#projectswitch-' + project.id).css('display', showItem ? 'block' : 'none');
            });
        }

        var buttons = j$('.sbbutton');
        buttons.each(function() {
            j$(this).click(function(e) {
                e.preventDefault();
                var rval = parseInt(sb.css.right);
                if (rval === 0 && j$("#content-" + j$(this).data('id') + ".active").length > 0) {
                    closeSbSwicher(e);
                } else if (j$("#sbcontent>div.active").length > 0) {
                    j$("#sbcontent>div.active").removeClass("active");
                    j$("#content-" + j$(this).data('id')).addClass('active');
                    if (j$(this).data('id') === 'ps') {
                        renderProjects();
                        setTimeout(function() { j$('#pskeywords').focus() }, 1000);
                    }
                } else {
                    if (j$(this).data('id') === 'ps') {
                        renderProjects();
                        setTimeout(function() { j$('#pskeywords').focus() }, 1000);
                    }

                    bg = j$("<div></div>").addClass("modal-dialog-backdrop").click(closeSbSwicher);
                    j$("body").append(bg);
                    j$("#content-" + j$(this).data('id')).addClass('active');
                    sb.animate({ "right": 0 }, 300, "easeOutCubic" );

                    setTimeout(function() { bg.addClass('backdrop-active') }, 10);
                }
            });
        });

        j$('#sbclose').click(closeSbSwicher);

        j$('#pskeywords').keyup(function (e) {
            renderProjects();
        });
    }
}

// Visible on every page. Shows a red element when the user is logged out.
export function loginCheck(userid, fullname) {
    var loginid = Cookie.read('__Host-loggedinas');
    var userid = userid;

    var msg = document.getElementById('loggedoutmsg');
    if (!loginid || loginid != userid) {
        if (!msg) {
            const el = document.createElement('div');
            el.setAttribute('class', 'core_msg msg_warning');
            el.setAttribute('id', 'loggedoutmsg');
            el.innerHTML = $I18N.t('You are logged out of your current account. This might have happened because your logged out or logged in as someone else in another window. Any changes that are not yet saved will be lost if you continue.');
            document.body.appendChild(el);
        }
    } else if (msg) {
        msg.remove();
    }
}

// Shows a green message in right bottom.
// Triggered by AJAX calls and form updates (function call will be added to DOM).
// showMessage('Opgeslagen.', 'success');
window.addEventListener('DOMContentLoaded', function(){
    globalThis.showMessage = function (msg, cls, showDuration) {
        cls = cls || 'success';
        showDuration = showDuration || 2500;

        var containerId = 'imlist';

        //try to reuse the existing container
        var messages = document.getElementById(containerId);
        if (!messages) {
            var messages = document.createElement('div');
            messages.setAttribute('id', containerId);
            messages.setAttribute('style', 'z-index: 100000');
            document.body.appendChild(messages);
        }

        var el = document.createElement('div');
        el.setAttribute('class', 'imitem');
        el.innerHTML = '<div class="core_msg msg_' + cls + '"><p>' + msg + '</p></div>';
        el.style.opacity = 1;
        el.style.transition = 'all 0.5s ease-in-out';

        document.getElementById(containerId).addEventListener('click', function () {
            this.remove();
        });

        messages.appendChild(el);
        setTimeout(function () {
            el.style.opacity = 0;
            setTimeout(function () {
                el.remove();
                if (messages.querySelectorAll('div.imitem').length == 0) {
                    messages.remove();
                }
            }, 250);
        }, showDuration);
    };
});

/**
 * Popup / modal, doing request and loads content in overlay. Apply JS / CSS. e.g.
 * <a href="/get/data/url" class="popup" data-params='{"width": "500px", "height": "300px"}'>Open popup</a>
 * /formurl should return a redirect ({redirect: /newurl}) or data: {
 *     "title": "Popup title",
 *     "content": "Popup content",
 *     "buttons": { "submit": {"title": "Submit", "class": "btn-primary", "icon": "fa-check"} }
 *     "js": "document.getElementById('form').addEventListener('submit', function(e) { e.preventDefault(); alert('Form submitted'); });",
 *     "css": "body { background-color: red; }"
 * }
 * Use PopupOutput class to generate css / js / html / buttons
 */
MpClick('.popup', function (el, e) {
    var popup = new Popup();
    var params = {};
    popup.options = JSON.parse(el.dataset.params ?? '{}');
    popup.options.href = el.href;

    if (!popup.options.href) {
        return;
    }
    popup.run();
});
export class Popup {
    constructor(container, options) {
        var popup = this;
        this.options = {};
    }

    attachForm(popup) {
        var popupButton = document.querySelectorAll('.popup_button');
        popupButton.forEach(function (el) {
            el.addEventListener('click', function (e) {
                var popupContainer = document.querySelector('.popupcontainer form');
                if (popupContainer) {
                    new MpRequest(popup.options.href, popupContainer, function (jsonObj) {
                        if (jsonObj) {
                            popup.overlay_content.innerHTML = '';
                            popup.buildOverlayContent(popup, jsonObj);
                        } else {
                            // close overlay
                            j$('#root-overlay').remove();
                            j$('#overlay_content').remove();
                        }
                    }).post();
                }
            });
        });
    }

    run(evt) {
        if (evt) {
            evt.preventDefault();
        }
        var popup = this;
        addOverlayHtml();
        this.overlay_content = document.getElementById('overlay_content');
        this.overlay_content.classList.add('popupcontainer');
        if (this.options.width) {
            this.overlay_content.style.maxWidth = this.options.width;
        }
        this.overlay_content.innerHTML = "<span style='display: block; text-align: center;'><span class='fa-spinner fa-spin' style='color: #fff; font-size: 2em'></span></span>";

        new MpRequest(this.options.href,function (jsonObj, e) {
            if (jsonObj) {
                popup.buildOverlayContent(popup, jsonObj);
            } else {
                // close overlay
                j$('#root-overlay').remove();
                j$('#overlay_content').remove();
            }
        }).get();
    }

    buildOverlayContent(popup, jsonObj) {
        popup.overlay_content.innerHTML = '';
        if (jsonObj.redirect) {
            var contentdiv = document.createElement('div');
            contentdiv.innerHTML = jsonObj.content;
            contentdiv.style.marginTop = '2em';

            popup.overlay_content.classList.remove('shadow');
            popup.overlay_content.appendChild(contentdiv);
            window.location = jsonObj.redirect;
            return;
        }

        var closeButton = this.options.close ? `<div class="options"><a id="popup-close" class="fa-times"></a></div>` : '';
        var titlediv = j$(`<div class="popup_title" id="popup_title">${closeButton}<h1>${jsonObj.title}</h1></div>`);
        var footerdiv = j$('<div class="popup_footer" id="popup_footer"></div>');
        var contentdiv = j$('<div class="popup_content" id="popup_content"></div>').html(jsonObj.content);

        if (jsonObj.buttons && jsonObj.buttons != '') {
            contentdiv.addClass('hasbuttons');

            for (var button in jsonObj.buttons) {
                var buttonClass = jsonObj.buttons[button].class || '';
                var iconClass = jsonObj.buttons[button].icon || '';
                var title = jsonObj.buttons[button].title || '';
                var el = `<button type="submit" id="frmsubmit" class="frmsubmitbutton submitbtn popup_button ${buttonClass}" name="${button}"><span class="${iconClass}"></span>${title}</button>`;
                j$(el).appendTo(footerdiv);
            }
        }
        if (this.options.height) {
            contentdiv.css('min-height', this.options.height);
        }

        j$(titlediv).appendTo(popup.overlay_content);
        j$(contentdiv).appendTo(popup.overlay_content);
        j$(footerdiv).appendTo(popup.overlay_content);
        popup.overlay_content.classList.add('shadow');
        popup.attachForm(popup);

        if (jsonObj.js && jsonObj.js !== '') {
            eval(jsonObj.js);

            // trigger DomContentLoaded event.. When new content relies on this event.
            var event = new Event('DOMContentLoaded', {
                bubbles: true,
                cancelable: true,
            });
            document.dispatchEvent(event);
        }

        if (jsonObj.css && jsonObj.jcsss !== '') {
            var sheet = document.createElement('style');
            sheet.innerHTML = jsonObj.css;
            document.body.appendChild(sheet);
        }

        _init_toggle();
        contentEvents();
        j$('.fa-spinner').remove();
    }
}

var lastContentSlider = 1;

/* Only vanilla JS! */
export function contentEvents() {
    var contentSliders = document.querySelectorAll('div.content-slider');
    for (let i = 0; i < contentSliders.length; ++i) {
        new contentSlider(contentSliders[i], 'slider-' + (i + lastContentSlider));
        lastContentSlider = i + lastContentSlider;
    }

    /* Question-icon hovers in the margin of a paragraph */
    var paragraphTooltips = document.querySelectorAll('p.content-paragraphtooltip');
    for (let i = 0; i < paragraphTooltips.length; ++i) {
        var el = paragraphTooltips[i];
        var tooltipHandle = document.createElement('div');
        tooltipHandle.classList.add('content-paragraphtooltip-handle');
        tooltipHandle.classList.add('tooltiphtml');
        tooltipHandle.setAttribute('data-content-tooltip-side', 'left');
        tooltipHandle.setAttribute('data-content-tooltip-html', el.getAttribute('data-content-tooltip-html'));
        el.appendChild(tooltipHandle);
        el.removeAttribute('data-content-tooltip-html');
    }

    /* Clickable div that will show the answer in open inline questions */
    var contentQuestions = document.querySelectorAll('div.content-question-feedback-showanswer');
    for( let i =0; i < contentQuestions.length; ++i) {
        var el = contentQuestions[i];
        contentQuestions[i].addEventListener('click', function () {
                var rightAnswerEl = this.parentNode.querySelectorAll('.content-question-feedback-right')[0];
                if (rightAnswerEl) {
                    this.style.display = 'none';
                    rightAnswerEl.style.display = 'block';
                }
            }
        );
    }

    /* Shuffle list elements of mc questions */
    var contentQuestionOptionLists = document.querySelectorAll('ul.content-question-options-shuffle');
    for( let i =0; i < contentQuestionOptionLists.length; ++i) {
        var contentQuestionOptionList = contentQuestionOptionLists[i];
        for (var n = contentQuestionOptionList.children.length; n >= 0; n--) {
            contentQuestionOptionList.appendChild(contentQuestionOptionList.children[Math.random() * n | 0]);
        }
    }

    /* mc and mc-multi questions */
    var contentQuestionOptionListItems = document.querySelectorAll('ul.content-question-options li');
    for( let i =0; i < contentQuestionOptionListItems.length; ++i) {
        var contentQuestionOptionListItem = contentQuestionOptionListItems[i];
        var contentQuestionOptionList = contentQuestionOptionListItem.parentNode;
        var contentQuestionId = contentQuestionOptionList.parentNode.getAttribute('data-content-question-id');

        var textElement = contentQuestionOptionListItem.getElementsByClassName('content-question-option-text')[0];

        var isRadio = contentQuestionOptionList.classList.contains('content-question-single');

        var inputElement = document.createElement('input');
        inputElement.setAttribute('type', isRadio ? 'radio' : 'checkbox');
        inputElement.setAttribute('id', 'question-element-' + i);
        if (isRadio) {
            inputElement.setAttribute('name', 'question-' + contentQuestionId);
        }
        contentQuestionOptionListItem.appendChild(inputElement);

        var labelElement = document.createElement('label');
        labelElement.classList.add('content-question-option-text');
        labelElement.setAttribute('for', 'question-element-' + i);
        labelElement.innerHTML = textElement.innerHTML;
        contentQuestionOptionListItem.appendChild(labelElement);

        textElement.parentNode.removeChild(textElement);
    }
    var contentQuestionButtons = document.querySelectorAll('span.content-question-submitbutton');
    for( let i =0; i < contentQuestionButtons.length; ++i) {
        contentQuestionButtons[i].addEventListener('click', function () {
                var rightAnswers = 0;
                var wrongAnswers = 0;
                var numChecked = 0;
                var questionNode = this.parentNode.parentNode;
                var answerOptions = questionNode.querySelectorAll('.content-question-options input');
                for (var o = 0; o < answerOptions.length; ++o) {
                    var answerOption = answerOptions[o];
                    var optionCorrect = answerOption.parentNode.classList.contains('content-question-option-correct');
                    if (answerOption.checked) {
                        numChecked++;
                        if (optionCorrect) {
                            rightAnswers++
                        } else {
                            wrongAnswers++
                        }
                    } else {
                        if (optionCorrect) {
                            wrongAnswers++
                        }
                    }
                }

                var wrongAnswerElement = questionNode.getElementsByClassName('content-question-feedback-wrong')[0];
                var rightAnswerElement = questionNode.getElementsByClassName('content-question-feedback-right')[0];

                if (wrongAnswerElement && rightAnswerElement && numChecked > 0) {
                    if (wrongAnswers > 0) {
                        wrongAnswerElement.style.display = 'block';
                        rightAnswerElement.style.display = 'none';
                    } else if (rightAnswers > 0) {
                        wrongAnswerElement.style.display = 'none';
                        rightAnswerElement.style.display = 'block';
                    } else {
                        wrongAnswerElement.style.display = 'none';
                        rightAnswerElement.style.display = 'none';
                    }
                }
            }
        );
    }

    /* Load RemindoTest iframes when they are in the current viewport */
    var contentArticleTests = document.querySelectorAll('div.article-testcontainer');
    var iframeLoaded = [];
    var loading = false;
    for (let i = 0; i < contentArticleTests.length; ++i) {
        var testContainer = contentArticleTests[i];
        iframeLoaded[i] = false;

        var isInViewport = function (el) {
            var bounding = el.getBoundingClientRect();
            return (
                bounding.top >= -1 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            );
        };
        if (!iframeLoaded[i]) {
            if (isInViewport(testContainer)) {
                if (false === loading) {
                    loading = true;
                    iframeLoaded[i] = true;
                    testContainer.innerHTML = '<iframe src="' + testContainer.getAttribute('data-iframe-url') + '" id="remindotoets-iframe-' + i + '" width="600" frameborder="0" scrolling="yes" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
                    iFrameResize({checkOrigin: false, maxHeight: 1000, scrolling: true}, '#remindotoets-iframe-' + i);
                } else {
                    testContainer.children[0].style.removeProperty('display');
                }
            } else {
                testContainer.innerHTML = '<p>' +  $I18N.t('Busy loading exam...') + '</p>';
            }
        }
    }
    var loadTestIframe = function () {
        for (let i = 0; i < contentArticleTests.length; ++i) {
            var testContainer = contentArticleTests[i];
            if (!iframeLoaded[i] && isInViewport(testContainer)) {
                iframeLoaded[i] = true;
                testContainer.innerHTML = '<iframe src="' + testContainer.getAttribute('data-iframe-url') + '" id="remindotoets-iframe-' + i + '" width="600" frameborder="0" scrolling="yes" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
                iFrameResize({checkOrigin: false, maxHeight: 1000, scrolling: true}, '#remindotoets-iframe-' + i);
            }
        }
    }
    window.addEventListener('scroll', loadTestIframe);

    var contentArticleTestButtons = document.querySelectorAll('div.article-testcontainer .article-remindotest-start');
    for (let ji = 0; ji < contentArticleTestButtons.length; ++ji) {
        contentArticleTestButtons[ji].addEventListener('click', function () {
            for (let j = 0; j < contentArticleTests.length; ++j) {
                var testContainer = contentArticleTests[j];
                if (!iframeLoaded[j] && isInViewport(testContainer) && testContainer.getAttribute('data-iframe-url') === this.parentElement.getAttribute('data-iframe-url')) {
                    iframeLoaded[j] = true;
                    testContainer.innerHTML = '<iframe src="' + testContainer.getAttribute('data-iframe-url') + '" id="remindotoets-iframe-' + i + '" width="600" frameborder="0" scrolling="yes" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
                    iFrameResize({checkOrigin: false, maxHeight: 1000, scrolling: true}, '#remindotoets-iframe-' + j);
                }
            }
        });
    }

    /* Highlight the toc on the right chapter */
    if (document.getElementsByClassName('article')[0] && document.getElementsByClassName('article-chapterindex-toc')[0]) {
        var articleBody = document.getElementsByClassName('article')[0];
        var topMostElement = null;
        var isInViewport = function (elem, i) {
            var bounding = elem.getBoundingClientRect();
            if (bounding.top < 0) {
                topMostElement = i;
            }
            return (
                bounding.top >= -1 &&
                bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                window.getComputedStyle(elem.parentNode).display !== 'none'
            );
        };

        var headerIndex = [];
        var tocLinks = document.querySelectorAll('div.article div.article-chapterindex-toc a');
        for( let i =0; i < tocLinks.length; ++i) {
            var tocLink = tocLinks[i];
            var linkRef = null;
            if (tocLink.getAttribute('href')) {
                linkRef = document.getElementById(tocLink.getAttribute('href').substring(1))
                if (linkRef) {
                    headerIndex[headerIndex.length] = {
                        tocElement: tocLink,
                        headerElement: linkRef
                    };
                }
            }
        }
        var headerSidebarIndex = [];
        var tocLinks = document.querySelectorAll('div#sidebar-responsive div.article-chapterindex-toc a');
        for( let i =0; i < tocLinks.length; ++i) {
            var tocLink = tocLinks[i];
            var linkRef = null;
            if (tocLink.getAttribute('href')) {
                linkRef = document.getElementById(tocLink.getAttribute('href').substring(1))
                if (linkRef) {
                    headerSidebarIndex[headerSidebarIndex.length] = {
                        tocElement: tocLink,
                        headerElement: linkRef
                    };
                }
            }
        }

        var previousActive = false;
        var viewportCheck = function () {
            for( let i =0; i < headerIndex.length; ++i) {
                if (headerIndex[i].tocElement && headerIndex[i].headerElement) {
                    if (isInViewport(headerIndex[i].headerElement, i)) {
                        if (!articleBody.contains(headerIndex[i].headerElement)) {
                            //remove element from array
                            headerIndex.splice(i, 1);
                            continue;
                        }
                        headerIndex[i].tocElement.classList.add('active');
                        headerSidebarIndex[i].tocElement.classList.add('active');

                        if (previousActive && i != previousActive) {
                            headerIndex[previousActive].tocElement.classList.remove('active');
                            headerSidebarIndex[previousActive].tocElement.classList.remove('active');
                        }
                        previousActive = i;
                        break;
                    } else if (false !== previousActive && previousActive == i) {
                        headerIndex[previousActive].tocElement.classList.remove('active');
                        headerSidebarIndex[previousActive].tocElement.classList.remove('active');
                        previousActive = false;
                    }
                }
            }
            if (!previousActive && topMostElement >= 0) {
                if (headerIndex[topMostElement]) {
                    headerIndex[topMostElement].tocElement.classList.add('active');
                }
                if (headerSidebarIndex[topMostElement]) {
                    headerSidebarIndex[topMostElement].tocElement.classList.add('active');
                }
                previousActive = topMostElement;
            }
        }
        if (headerIndex.length > 0) {
            window.addEventListener('scroll', viewportCheck);
            window.addEventListener('resize', viewportCheck);
        }
    }
}
/* Enlarge images in a modal dialog */
MP.util.watch('img.content-enlargeimage', function (enlargeImage) {
    var anchor = document.createElement('a');
    anchor.setAttribute('href', enlargeImage.getAttribute('src').replace(/_[0-9a-f]{16}_\d+x\d+\.(jpg|png|gif)$/, '.$1'));
    anchor.setAttribute('title', enlargeImage.getAttribute('data-content-tooltip-text'));
    anchor.setAttribute('mp-milkbox', 'image');
    anchor.appendChild(enlargeImage.cloneNode(true));
    enlargeImage.parentNode.replaceChild(anchor, enlargeImage);
});

var contentSlider = (function () {
    var init = function (element, sliderId) {
        this.element = element;
        this.tabs = [];
        this.sliderId = sliderId || 'slider-' + Math.floor(Math.random() * 999999);
        var tab = null;
        var childNodes = element.childNodes;
        for (var i = 0; i < childNodes.length; i++) {
            var childNode = childNodes[i];
            var tagName = childNode.tagName;
            if (tagName) {
                if (tagName.toLowerCase() === 'h3') {
                    tab = new contentTab(childNode, sliderId, this.tabs.length);
                    this.tabs[this.tabs.length] = tab;
                } else if (tab) {
                    tab.addElement(childNode);
                }
            }
        }
        this.render = function () {
            if (this.tabs.length === 0) {
                return;
            }
            var mobileToggler = document.createElement('span');
            mobileToggler.classList.add('content-slider-mobiletoggler');
            mobileToggler.addEventListener('click', function () {
                if (this.element.classList.contains('content-slider-toggleopen')) {
                    this.element.classList.remove('content-slider-toggleopen');
                } else {
                    this.element.classList.add('content-slider-toggleopen');
                }
                event.preventDefault();
            }.bind(this));
            this.element.appendChild(mobileToggler);

            var tabs = document.createElement('ul');
            tabs.classList.add('content-slider-tab', 'fix');
            this.element.appendChild(tabs);

            var tabContents = document.createElement('div');
            tabContents.classList.add('content-slider-tabcontents');
            this.element.appendChild(tabContents);

            for (var i = 0; i < this.tabs.length; i++) {
                this.tabs[i].render(tabs, tabContents, this);
            }

            var activeTab = 0;
            var anchor = document.location.hash;
            if (anchor.indexOf('#' + this.sliderId) == 0) {
                var tabNum = parseInt(anchor.substring(this.sliderId.length + 2), 10);
                if (!isNaN(tabNum) && tabNum >= 0 && tabNum < this.tabs.length) {
                    activeTab = tabNum;
                }
            }

            this.setActiveTab(activeTab);
        }
        this.setActiveTab = function (activeTab) {
            for (var i = 0; i < this.tabs.length; i++) {
                this.tabs[i].setActive(activeTab == i);
            }
            this.element.classList.remove('content-slider-toggleopen');
        }

        this.render();
    }
    var contentTab = function (titleElement, sliderId, tabIndex) {
        this.contentSidebar = titleElement.dataset.contentSidebar ? JSON.parse(titleElement.dataset.contentSidebar) : null;
        this.titleElement = titleElement;
        this.title = titleElement.textContent;
        this.sliderId = sliderId;
        this.tabIndex = tabIndex;
        this.identifier = this.sliderId + '-' + this.tabIndex;
        this.elements = [];
        this.tabListItem = null;
        this.tabContent = null;
        this.addElement = function (element) {
            this.elements[this.elements.length] = element;
        }
        this.render = function (tabs, tabContents, sliderObject) {
            //add Title
            this.tabListItem = document.createElement('li');
            var a = document.createElement('a');
            a.setAttribute('href', '#' + this.identifier);
            a.textContent = this.title;

            a.addEventListener('click', function (identifier, tabIndex, sliderObject) {
                history.replaceState(undefined, undefined, '#' + identifier);
                sliderObject.setActiveTab(tabIndex);
                event.preventDefault();
            }.bind(this, this.identifier, this.tabIndex, sliderObject));

            this.tabListItem.appendChild(a);
            tabs.appendChild(this.tabListItem);
            this.titleElement.parentNode.removeChild(this.titleElement);

            //add Elements
            this.tabContent = document.createElement('div');
            this.tabContent.classList.add('content-slider-tabcontent', 'fix');
            this.tabContent.setAttribute('id', this.identifier);
            for (var i = 0; i < this.elements.length; i++) {
                this.tabContent.appendChild(this.elements[i]);
            }
            tabContents.appendChild(this.tabContent);
        }
        this.setActive = function (isActive) {
            // @todo: cleanup
            //everything related to tabToc is garbage and should be removed when the articles are refactored
            var tabToc = document.getElementsByClassName('toc-slider-' + tabIndex);
            if (isActive) {
                if (this.contentSidebar) {
                    var contentSidebar = this.contentSidebar;
                    Object.keys(this.contentSidebar).forEach(function (key) {
                        if (document.getElementById(key)) {
                            var sidebarEntry = document.querySelectorAll('ul#' + key + ' li');
                            if (contentSidebar[key].length > 0) {
                                document.getElementById(key).parentNode.parentNode.classList.remove('hide');
                                for (var i = 0; i < sidebarEntry.length; i++) {
                                    var tmp = sidebarEntry[i].id.split('_');
                                    if (contentSidebar[key].includes(parseInt(tmp[1]))) {
                                        sidebarEntry[i].classList.remove('hide');
                                    } else {
                                        sidebarEntry[i].classList.add('hide');
                                    }
                                }
                            } else {
                                document.getElementById(key).parentNode.parentNode.classList.add('hide');
                            }
                        }
                    });
                }

                if (this.sliderId === 'slider-1') {
                    for (var i = 0; i < tabToc.length; i++) {
                        tabToc[i].style.display = 'block';
                        document.getElementById('article-toc').style.display = tabToc[i].innerText === '' ? 'none' : 'block';
                    }
                }

                this.tabListItem.classList.add('active');
                this.tabContent.classList.add('active');
            } else {
                if (this.sliderId === 'slider-1') {
                    for (var i = 0; i < tabToc.length; i++) {
                        tabToc[i].style.display = 'none';
                    }
                }
                this.tabListItem.classList.remove('active');
                this.tabContent.classList.remove('active');
            }
        }
    };
    return init;
})();

export function checkPasswordStrength(element) {
    var passwordContext = (element.getAttribute('data-password-context') || '').split(',');
    var passwordMeterId = element.getAttribute('data-password-strength-meter');
    var passwordFeedbackId = element.getAttribute('data-password-strength-feedback');
    var passwordMeter = document.getElementById(passwordMeterId);
    var passwordFeedback = document.getElementById(passwordFeedbackId);

    if (!passwordMeter || !passwordFeedback || typeof zxcvbn === 'undefined') {
        return;
    }

    var dictionary = {
        'A word by itself is easy to guess': $I18N.t('A word by itself is easy to guess'),
        'Add another word or two. Uncommon words are better.': $I18N.t('Add another word or two. Uncommon words are better.'),
        'All-uppercase is almost as easy to guess as all-lowercase': $I18N.t('All-uppercase is almost as easy to guess as all-lowercase'),
        'Avoid dates and years that are associated with you': $I18N.t('Avoid dates and years that are associated with you'),
        'Avoid recent years': $I18N.t('Avoid recent years'),
        'Avoid repeated words and characters': $I18N.t('Avoid repeated words and characters'),
        'Avoid sequences': $I18N.t('Avoid sequences'),
        'Avoid years that are associated with you': $I18N.t('Avoid years that are associated with you'),
        'Capitalization doesn\'t help very much': $I18N.t('Capitalization doesn\'t help very much'),
        'Common names and surnames are easy to guess': $I18N.t('Common names and surnames are easy to guess'),
        'Dates are often easy to guess': $I18N.t('Dates are often easy to guess'),
        'Names and surnames by themselves are easy to guess': $I18N.t('Names and surnames by themselves are easy to guess'),
        'Predictable substitutions like \'@\' instead of \'a\' don\'t help very much': $I18N.t('Predictable substitutions like \'@\' instead of \'a\' don\'t help very much'),
        'Recent years are easy to guess': $I18N.t('Recent years are easy to guess'),
        'Repeats like "aaa" are easy to guess': $I18N.t('Repeats like "aaa" are easy to guess'),
        'Repeats like "abcabcabc" are only slightly harder to guess than "abc"': $I18N.t('Repeats like "abcabcabc" are only slightly harder to guess than "abc"'),
        'Reversed words aren\'t much harder to guess': $I18N.t('Reversed words aren\'t much harder to guess'),
        'Sequences like abc or 6543 are easy to guess': $I18N.t('Sequences like abc or 6543 are easy to guess'),
        'Short keyboard patterns are easy to guess': $I18N.t('Short keyboard patterns are easy to guess'),
        'Straight rows of keys are easy to guess': $I18N.t('Straight rows of keys are easy to guess'),
        'This is a top-10 common password': $I18N.t('This is a top-10 common password'),
        'This is a top-100 common password': $I18N.t('This is a top-100 common password'),
        'This is a very common password': $I18N.t('This is a very common password'),
        'This is similar to a commonly used password': $I18N.t('This is similar to a commonly used password'),
        'Use a few words, avoid common phrases': $I18N.t('Use a few words, avoid common phrases'),
        'Use a longer keyboard pattern with more turns': $I18N.t('Use a longer keyboard pattern with more turns'),
        'The password must be at least 8 characters long': $I18N.t('The password must be at least 8 characters long'),
        'Use at least 1 uppercase letter, 1 lowercase letter and 1 digit': $I18N.t('Use at least 1 uppercase letter, 1 lowercase letter and 1 digit'),
        'Use at least 1 uppercase letter': $I18N.t('Use at least 1 uppercase letter'),
        'Use at least 1 lowercase letter': $I18N.t('Use at least 1 lowercase letter'),
        'Use at least 1 digit': $I18N.t('Use at least 1 digit')
    };

    passwordMeter.classList.remove(
        'frm-passwordmeter-strength-0',
        'frm-passwordmeter-strength-1',
        'frm-passwordmeter-strength-2',
        'frm-passwordmeter-strength-3',
        'frm-passwordmeter-strength-4'
    );
    element.classList.remove(
        'weakpassword',
        'strongpassword'
    );
    var suggestions = $I18N.t('Use at least 1 uppercase letter, 1 lowercase letter and 1 digit');
    if (element.value.length > 0) {

        var result = zxcvbn(element.value, passwordContext);

        var passwordScore = result.score;

        if (element.value.length < 8) {
            result.feedback.suggestions.push('The password must be at least 8 characters long');
            passwordScore = passwordScore < 1 ? 0 : 1;
        }
        if (!element.value.match(/[A-Z]+/) && !element.value.match(/[a-z]+/) && !element.value.match(/[0-9]+/)) {
            result.feedback.suggestions.push('Use at least 1 uppercase letter, 1 lowercase letter and 1 digit');
            passwordScore = passwordScore < 1 ? 0 : 1;
        } else {
            if (!element.value.match(/[A-Z]+/)) {
                result.feedback.suggestions.push('Use at least 1 uppercase letter');
                passwordScore = passwordScore < 1 ? 0 : 1;
            }
            if (!element.value.match(/[a-z]+/)) {
                result.feedback.suggestions.push('Use at least 1 lowercase letter');
                passwordScore = passwordScore < 1 ? 0 : 1;
            }
            if (!element.value.match(/[0-9]+/)) {
                result.feedback.suggestions.push('Use at least 1 digit');
                passwordScore = passwordScore < 1 ? 0 : 1;
            }
        }
        passwordMeter.classList.add('frm-passwordmeter-strength-' + passwordScore);

        element.classList.add(passwordScore >= 3 ? 'strongpassword' : 'weakpassword');

        suggestions = '';
        if (result.feedback.warning) {
            suggestions += '<strong>' + dictionary[result.feedback.warning] + '</strong>';
        }
        if (result.feedback.suggestions.length > 0) {
            suggestions += '<ul style="margin:0;padding:0;list-style:none">';
            result.feedback.suggestions.forEach(function (sug) {
                if (dictionary[sug]) {
                    suggestions += '<li>' + dictionary[sug] + '</li >';
                }
            });
            suggestions += '</ul>';
        }
    }
    passwordFeedback.innerHTML = suggestions;
}

export function delay(callback, ms) {
    var timer = 0;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

export function kvkLookup(type, field, mapping, kvklocation) {
    var el = document.getElementById(field);
    if (!(type === 'company' || type === 'number') || !el) {
        return;
    }

    var suggestElement = document.createElement('div');
    suggestElement.id = 'suggest-' + field;
    suggestElement.classList.add('frmrow', 'nolabel');
    el.parentNode.appendChild(suggestElement);

    el.addEventListener('keydown', function (e) {
        document.getElementById('suggest-' + field).innerHTML = '';
    });

    el.addEventListener('blur', delay(function (e) {
        //document.getElementById('suggest-' + field).innerHTML = '';
    }, 500));

    el.addEventListener('keyup', delay(function (e) {
        var fieldValue = this.value;
        if (fieldValue.length < 3) {
            return;
        }
        var urlLocationParam = '';
        if (typeof kvklocation !== 'undefined') {
            urlLocationParam = '&' + 'kvklocation=' + encodeURIComponent(kvklocation);
        }

        var xhr = new XMLHttpRequest();
        xhr.responseType = 'json';
        xhr.withCredentials = true;
        xhr.open('GET', '/kvk/search?' + encodeURIComponent(type) + '=' + encodeURIComponent(fieldValue) + urlLocationParam, true);
        xhr.onload = function () {
            if (xhr.status != 200 || !xhr.response) {
                return;
            }

            var suggestField = document.getElementById('suggest-' + field);
            var suggestList = document.createElement('ul');
            suggestList.classList.add('bulleted')
            suggestList.style.margin = '0.5em 0';
            suggestField.appendChild(suggestList);

            for (var i = 0; i < xhr.response.length; i++) {
                var resultItem = xhr.response[i];

                var suggestListItem = document.createElement('li');
                suggestList.appendChild(suggestListItem);

                var suggestItem = document.createElement('a');
                suggestItem.setAttribute('href', '#');
                suggestItem.appendChild(document.createTextNode(resultItem.businessName));

                suggestListItem.appendChild(suggestItem);
                if (typeof kvklocation !== 'undefined' && typeof resultItem[kvklocation] !== 'undefined') {
                    var suggestItemLink = document.createElement('a');
                    suggestItemLink.setAttribute('href', resultItem[kvklocation]);
                    suggestItemLink.setAttribute('class', 'fa-exclamation-triangle opt-iconorange tooltiphtml');
                    suggestItemLink.setAttribute('data-content-tooltip-html', '<b>' + $I18N.t('Please note:') + '</b> ' + $I18N.t('this organization already appears in the CRM.') + ' <br /><b>' + $I18N.t('Click here to go to this organization') + '</b>');
                    suggestListItem.appendChild(suggestItemLink);
                }

                suggestItem.addEventListener('click', function (e, resultItem) {
                    for (var formFieldName in mapping) {
                        var formField = document.getElementById(formFieldName);
                        if (formField) {
                            var resultField = mapping[formFieldName];
                            if (resultField.indexOf('.') != -1) {
                                if (resultItem[resultField.split('.')[0]] && resultItem[resultField.split('.')[0]][resultField.split('.')[1]]) {
                                    formField.value = resultItem[resultField.split('.')[0]][resultField.split('.')[1]];
                                }
                            } else {
                                formField.value = resultItem[resultField];
                            }
                        }
                    }
                    suggestField.innerHTML = '';
                    e.preventDefault();
                }.bind(this, event, resultItem));
            }
        };
        xhr.send();
    }, 900));
}

/** vanilla JS */

//just for IE11...
if (!Array.from) {
    Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) {
                return 0;
            }
            if (number === 0 || !isFinite(number)) {
                return number;
            }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Let C be the this value.
            var C = this;

            // 2. Let items be ToObject(arrayLike).
            var items = Object(arrayLike);

            // 3. ReturnIfAbrupt(items).
            if (arrayLike == null) {
                throw new TypeError("Array.from requires an array-like object - not null or undefined");
            }

            // 4. If mapfn is undefined, then let mapping be false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
                // 5. else
                // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                if (!isCallable(mapFn)) {
                    throw new TypeError('Array.from: when provided, the second argument must be a function');
                }

                // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                if (arguments.length > 2) {
                    T = arguments[2];
                }
            }

            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);

            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);

            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
                kValue = items[k];
                if (mapFn) {
                    A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                } else {
                    A[k] = kValue;
                }
                k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
        };
    }());
}

export function isString(x) {
    return Object.prototype.toString.call(x) === '[object String]';
}


export function dynReportToggleOnField(fieldName) {
    var elements = document.getElementsByName(fieldName);

    var dynReportToggler = function () {
        for (var i = 0; i < elements.length; i++) {
            var targetDiv = 'div_' + fieldName + '_' + elements[i].value;
            if (document.getElementById(targetDiv)) {
                if (elements[i].checked) {
                    slideDown(document.getElementById(targetDiv));
                } else {
                    slideUp(document.getElementById(targetDiv));
                }
            }
        }
    };
    dynReportToggler();
    for (var i = 0; i < elements.length; ++i) {
        elements[i].addEventListener('change', dynReportToggler);
    }
}



/* Old toggler code */
/** small timeout, was 0 but some pages are maybe a bit too heavy and never trigger the transitionend event, makes the slideUp behave oddly */
var toggleTimeout = 25;

export function slideUp(container, callback) {
    if (isString(container)) {
        container = j$('#' + j$.escapeSelector(container));
    }
    if (container instanceof jQuery) {
        container = container[0];
    }
    if (!container || container.classList === undefined) {
        return;
    }
    if (container.classList.contains('active')) {
        container.style.overflow = 'hidden';

        /** Get the computed height of the container. */
        var height = container.clientHeight + "px"
        container.style.height = height

        /** Set the height to 0px to trigger the slide up animation. */
        setTimeout(function () {
            container.style.height = "0px";
            container.style.opacity = "0";
        }, toggleTimeout)

        /** Remove the `active` class when the animation ends. */
        container.addEventListener('transitionend', function () {
            container.classList.remove('active');

            if (callback) {
                window[callback]();
            }
        }, {once: true})
    }
}

export function slideDown(container, callback) {
    if (isString(container)) {
        container = j$('#' + j$.escapeSelector(container));
    }
    if (container instanceof jQuery) {
        container = container[0];
    }
    if (!container || container.classList === undefined) {
        return;
    }
    if (!container.classList.contains('active')) {
        /** Show the container. */
        container.style.opacity = "0";
        container.classList.add('active');
        container.style.height = "auto";

        /** Get the computed height of the container. */
        var height = container.clientHeight + "px"

        /** Set the height of the content as 0px, */
        /** so we can trigger the slide down animation. */
        container.style.height = "0px";

        /** Do this after the 0px has applied. */
        /** It's like a delay or something. MAGIC! */
        setTimeout(function () {
            container.style.height = height
            container.style.opacity = "1"
        }, toggleTimeout);

        /** Remove the height when the animation ends. (elements inside the div might also do some sliding..) */
        container.addEventListener('transitionend', function () {
            container.style.height = null;
            container.style.overflow = 'visible';

            if (callback) {
                window[callback]();
            }
        }, {once: true})
    }
}

export function slideToggle(el, state) {
    if (isString(el)) {
        el = document.querySelector(el);
    }
    if (el instanceof jQuery) {
        el = el[0];
    }
    if (!el || el.classList === undefined) {
        return;
    }
    if (state === undefined) {
        state = !el.classList.contains('active');
    }
    if (state) {
        slideDown(el);
    } else {
        slideUp(el);
    }
}

j$(document).on('click', '.toggler', function (e) {
    e.preventDefault();

    if (this.classList.contains('disabled')) {
        return;
    }
    if (this.classList.contains('toggleonce') && this.classList.contains('toggled')) {
        return;
    }

    this.hash = this.hash || this.getAttribute('data-target');

    var target = document.querySelector(this.hash) || this.nextElementSibling;
    var elIcon = document.querySelector(this.hash + '_icon');

    if (target.innerHTML.length > 0) {
        slideToggle(target, target.offsetHeight === 0);
        elIcon?.classList.toggle('active');
    }

    if (this.dataset.focus) {
        var focus = this.dataset.focus;
        if (document.getElementById(focus)) {
            document.getElementById(focus).focus();
        }
    }
    if (this.classList.contains('toggleonce')) {
        this.classList.add('toggled');
        this.classList.remove('toggler');
        if (elIcon) {
            elIcon.parentNode.remove(elIcon);
        }
    }
});

export function _init_toggle() {
    j$('.toggler .options a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        window.location = j$(this).attr('href');
    });

    j$('.toggle_prev').each(function () {
        if (!j$(this).prev().hasClass('toggle')) {
            j$(this).prev().addClass('toggle');
        }
        j$(this).prev().removeClass('active');

        var msgs = {};
        if (j$(this).children('span').length > 0) {
            var plusmin = j$(this).children('span')[0];
            msgs = plusmin.innerHTML.split('|');
            plusmin.innerHTML = msgs[0];
        }
        j$(this).click(function () {
            if (j$(this).prev().hasClass('active')) {
                slideUp(j$(this).prev());
                plusmin.innerHTML = msgs[0];
            } else {
                slideDown(j$(this).prev());
                plusmin.innerHTML = msgs[1];
            }
        });
    });
}

// todo: replace slideUp and slideDown with toggler-js
/**
 * Add class .toggler-js to the element that handles the toggle.
 * With multiple toggles, e.g. in a form, add: .toggle-js to the next targeted element.
 * If no toggle-js is found, the next sibling will be toggled.
 * If you want to toggle a target element, add data-target-value="prev" to toggle the previous sibling or use data-target="selector".
 * If you want a rotating arrow, add the FA arrow down. Rotation will be handled by the script.
 */
MpClick('.toggler-js', function (el, event) {
    // prevent function call twice (when triggered via label)
    if (event.srcElement.tagName === 'LABEL') {
        return;
    }
    var target = getToggleTarget(el);
    if (!target) { return }

    target.style.gridTemplateRows = target.style.gridTemplateRows === '1fr' ? '0fr' : '1fr';
    el.classList.toggle('rotate-arrow-js', target.style.gridTemplateRows === '1fr');
});
/**
 * Trick to toggle content without knowing the height, using grid
 * https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/
 * Problem is: content must be wrapped, so we will do that in JS
 * This CSS tranisition is much smoother than via JS
 */
MP.util.watch('.toggler-js', function (el) {
    var target = getToggleTarget(el);
    if (!target) { return }

    target.style.display = 'grid';
    target.style.transition = 'grid-template-rows 0.5s ease-out';
    var fr = target.offsetHeight === 0 || el.querySelector('input:not(:checked)') ? '0fr' : '1fr';
    target.style.gridTemplateRows = fr;
    el.classList.toggle('rotate-arrow-js', fr === '1fr');

    // wrap content with div
    if (target.querySelector('.h') === null) {
        var inner = document.createElement('div');
        inner.style.overflow = 'hidden';
        inner.innerHTML = target.innerHTML;
        target.innerHTML = '';
        target.appendChild(inner);
    }
});

export function getToggleTarget(el) {
    if (el.dataset.targetValue === 'prev') {
        return el.previousElementSibling;
    }
    if (el.dataset.target) {
        return document.querySelector(el.dataset.target);
    }
    return smartNext(el, '.toggle-js', 2) || el.nextElementSibling;
}

// Find next element, no matter the depth
export function smartNext(el, search, maxDepth = 10) {
    var jCurrent = j$(el);
    var currentDepth = 0;
    while (!jCurrent.is('body') && currentDepth < maxDepth) {
        if (jCurrent.nextAll(search).length > 0) {
            return jCurrent.nextAll(search).first()[0];
        }
        if (jCurrent.nextAll().find(search).length > 0) {
            return jCurrent.nextAll().find(search).first()[0];
        }
        jCurrent = jCurrent.parent();
        currentDepth++;
    }

    return null;
}

// Find previous element, no matter the depth
export function smartPrev(el, search) {
    var jCurrent = j$(el);
    while (!jCurrent.is('body')) {
        if (jCurrent.prevAll().find(search).length > 0) {
            return jCurrent.prevAll().find(search).last();
        }
        jCurrent = jCurrent.parent();
    }

    return null;
}

export function getVisibileSize(el) {
    var clone = el.cloneNode(true);
    el.parentNode.insertBefore(clone, el.nextSibling);
    clone.style = {}; // reset style
    clone.style.height = 'auto';
    clone.style.width = 'auto';
    clone.style.display = 'block';
    var rect = clone.getBoundingClientRect(); // get size
    clone.remove();

    return {
        width: rect.width,
        height: rect.height
    };
}

export class HistoryManager {
    set(el, val) {
        const hash = this.getHash();

        if (hash) {
            const obj = this.deserializeHash(hash);
            obj[el] = val;
            window.location.hash = JSON.stringify(obj);
        } else {
            window.location.hash = JSON.stringify({
                [el]: val
            });
        }
    }

    getHash() {
        return window.location.hash;
    }

    deserializeHash(e) {
        if (e.length < 1) return {};
        e = e.substr(1);
        try {
            return JSON.parse(decodeURIComponent(e));
        } catch (e) {
            return {}; // when user change hash, it can be invalid
        }
    }
}


window.addEventListener('DOMContentLoaded', function () {
    // 'pop_download' not found in project?
    j$('a.pop_download').click(function(e){
        e.preventDefault();

        new MpRequest(this.href, {}, function (jsonObj) {
            document.body.grab(overlay_content); // Mootools
            var overlayContent = document.getElementById('overlay_content');
            overlayContent.innerHTML = jsonObj.content;
            overlayContent.classList.add('overlay_wide');
        }).post();
    });
});


window.requestAnimFrame = (function(){
    return  window.requestAnimationFrame       ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame    ||
        window.oRequestAnimationFrame      ||
        window.msRequestAnimationFrame     ||
        function(/* function */ callback, /* DOMElement */ element){
            window.setTimeout(callback, 1000 / 60);
        };
})();

// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

/**
 * This is not the real MooTools-Milkbox, but a simple plain JS lightbox
 *
 * Usage:
 * Both attributes, data-milkbox and data-mp-modal are supported. But please use data-mp-modal in the future.
 * Options:
 * data-milkbox-size="width:1280,height:720" or max
 * data-milkbox="single" || {name} for gallery
 *
 * <a href="path/to/image.jpg" data-milkbox="gallery-name" title="Image title">Image</a>
 */
var items = []; // list containing all items in the gallery (href and title)
var currentIndex = 0;
var simpleControlOptionsEnabled = true;

// Init on modal click..
MpClick('[data-milkbox], [data-mp-modal]', function (el) {
    // get all items in the gallery (by names: data-milkbox="name" or data-mp-modal="name")
    var name = el.getAttribute('data-milkbox') || el.getAttribute('data-mp-modal');
    var galeryItems = document.querySelectorAll('[data-milkbox=' + name + '], [data-mp-modal=' + name + ']');
    if (name === 'single' || name === null) {
        galeryItems = [el];
    }
    galeryItems.forEach(function (ell, i) {
        if (ell === el) {
            currentIndex = i; // set clicked el as current, so we know where to start in multi-item galleries
        }
        var src = ell.getAttribute('href');
        items.push({
            src: src,
            title: ell.title,
            size:  ell.dataset.milkboxSize ?? 'min' // data-milkbox-size="width:1280,height:720"
        });

        // prevent custom close options for elearning / scorm modules.
        // Disabled when src contains 'elearning' or 'scorm'
        simpleControlOptionsEnabled = src.indexOf('elearning') === -1 && src.indexOf('scorm') === -1;
    });
    loadSlide(currentIndex);
});

// get modal HTML
export function getModal() {
    var modal = document.querySelector('.milkbox-modal-wrapper');

    if (modal) {
        return modal;
    }

    modal = document.createElement('div');
    modal.classList.add('milkbox-modal-wrapper');
    modal.innerHTML = `<div class="milkbox-modal">
                <div class="milkbox-close">
                    <i class="fas fa-times"></i>
                </div>
                <div class="milkbox-content"></div>
                <div class="milkbox-prev">
                    <i class="fas fa-chevron-left"></i>
                </div>
                <div class="milkbox-next">
                    <i class="fas fa-chevron-right"></i>
                </div>
                <div class="milkbox-title"></div>
                <div class="milkbox-counter"></div>
            </div>`;

    document.body.appendChild(modal);

    // Apply opacity in next JS operation (so the transition is visible)
    setTimeout(function () {
        modal.querySelector('.milkbox-modal').style.opacity = 1;
    }, 100);

    return modal;
}

export function loadSlide(index) {
    if (index < 0) {
        currentIndex = 0;
        return;
    }
    if (index >= items.length) {
        currentIndex = items.length - 1;
        return;
    }
    var modal = getModal();
    var content = modal.querySelector('.milkbox-content');
    var item = items[index];

    var extensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'svg'];
    var isIframe = extensions.indexOf(item.src.split('.').pop()) === -1;
    if (isIframe) {
        modal.classList.add('milkbox-file');
        content.innerHTML = createIframe(item.src);
    } else {
        modal.classList.remove('milkbox-file');
        var curModal = document.querySelector('.milkbox-modal');
        var img = document.createElement('img');
        img.src = item.src;
        img.classList.add('zoom-js');
        img.onload = function () {
            content.innerHTML = '';
            content.append(img);
        };
    }

    modal.querySelector('.milkbox-title').innerHTML = item.title;

    // hide prev/next if first/last
    modal.querySelector('.milkbox-prev').style.display = index !== 0 ? 'flex' : 'none';
    modal.querySelector('.milkbox-next').style.display = index !== items.length - 1 ? 'flex' : 'none';

    // set counters
    if (items.length === 1) {
        modal.querySelector('.milkbox-counter').style.display = 'none';
    } else {
        modal.querySelector('.milkbox-counter').innerHTML = (index + 1) + '/' + items.length;
    }
    // hide milkbox-title if empty
    modal.querySelector('.milkbox-title').style.display = item.title ? 'block' : 'none';

    // fix size
    if (item.size === 'max') { // max
        content.style.width = '100vw';
        content.style.height = '100vh';
    } else if (item.size.indexOf(',') === -1) { // min content (default)
        content.style.width = '';
        content.style.height = '';
    } else { // passed data as string; width:1280,height:720
        var size = item.size.split(',');
        content.style.width = size[0].split(':')[1] + 'px';
        content.style.height = (size[1].split(':')[1] - 0 + 10) + 'px'; // add 10px to prevent scrollbars
    }
}

export function closeModal() {
    document.querySelector('.milkbox-modal-wrapper')?.remove();
    items = [];
    currentIndex = 0;
}

// Arrow handling for modal
document.addEventListener('keydown', function (e) {
    // return if no modal is open
    if (!document.querySelector('.milkbox-modal')) {
        return;
    }

    // prevent custom close options for elearning / scorm modules
    if (simpleControlOptionsEnabled === false) {
        return;
    }

    if (e.key === 'ArrowRight') {
        loadSlide(++currentIndex);
    }
    if (e.key === 'ArrowLeft') {
        loadSlide(--currentIndex);
    }
    if (e.key === 'Escape') {
        closeModal();
    }
});

export function createIframe(src) {
    return `<iframe src="${src}" frameBorder="0" width="1280" height="720" style="border: none; width: 100%; height: 100%;"></iframe>`;
}

MpClick('.milkbox-prev', function () {
    loadSlide(--currentIndex);
});
MpClick('.milkbox-next', function () {
    loadSlide(++currentIndex);
});
MpClick('.milkbox-close', function () {
    closeModal();
});
MpClick('.milkbox-modal-wrapper', function (el, event) {
    // close only if clicked on overlay
    if (simpleControlOptionsEnabled && event.target.matches('.milkbox-modal-wrapper')) {
        closeModal();
    }
});

// arrow keys
export function arrowHandler(e) {
    // return if no modal is open
    if (!document.querySelector('.milkbox-modal')) {
        return;
    }

    // prevent custom close options for elearning / scorm modules
    if (simpleControlOptionsEnabled === false) {
        return;
    }

    if (e.keyCode === 39) {
        loadSlide(++currentIndex);
    }
    if (e.keyCode === 37) {
        loadSlide(--currentIndex);
    }
    if (e.keyCode === 27) {
        closeModal();
    }
}



// userorganisation JS
j$(document).ready(function () {
    let contactReq = null;
    let orgReq = null;

    function updateOrg() {
        orgReq?.abort(); // update prev request if still running
        orgReq = j$.get('/userorganisation/getorganisation', {
            user_id: j$('#user_id')?.val(),
            userorganisation_id: document.getElementById('sel_userorganisationid')?.value,
            form_name: j$('#user_id')?.attr('name')?.split('_')[0] ?? 'edit',
            time: new Date().getTime()
        }, function (data) {
            // todo: translate
            j$('#userorganisation_fields').html(data?.html ?? 'De gegevens konden niet worden opgehaald.');
            j$('#userorganisation_fields').toggleClass('active', document.getElementById('sel_userorganisationid')?.value !== null);
            jQuery.addFileInput(j$('#userorganisation_fields').find('.fileinput-button span')?.attr('id') ?? '');
        }, 'json');
    }

    function updateContact() {
        contactReq?.abort(); // update prev request if still running
        contactReq = j$.get('/userorganisation/getcontact', {
            user_id: j$('#user_id')?.val(),
            userorganisation_id: document.getElementById('sel_userorganisationid')?.value,
            userorganisationcontact_id: document.getElementById('sel_userorganisationcontact_id')?.value,
            form_name: j$('#user_id')?.attr('name')?.split('_')[0] ?? 'edit',
            time: new Date().getTime()
        }, function (data) {
            // todo: translate
            j$('#userorganisationcontact_fields').html(data?.html ?? 'De gegevens konden niet worden opgehaald.');
            j$('#userorganisationcontact_fields').toggleClass('active', document.getElementById('sel_userorganisationcontact_id')?.value !== null);
        }, 'json');
    }

    j$('body').on('change', '#sel_userorganisationcontact_id', updateContact);
    j$('body').on('select2:select', '#sel_userorganisationid', updateOrg);
});


//table select:
export const TableSelect = class {
    options = {
        tableselect_info_id: 'tableselect_info'
        ,checkall_id: 'checkall'
        ,checkboxes_selector: 'input[name="_cb[]"]'
        ,saved_selection_id: 'saved_selection'
        ,select_action_on_id: 'select_action_on'
        ,select_action_id: 'select_action'
        ,select_action_submit_id: 'select_action_submit'
        ,pager_selector: '.pager a'
        ,cookie_name: 'saved_selection'
    };

    tableselect_info = null;
    checkall = null;
    checkboxes = null;
    saved_selection = null;
    select_action_on = null;
    select_action = null;
    select_action_submit = null;
    selection_all = false;
    // selection = [];

    constructor(options) {
        this.options = Object.assign(this.options, options);  // merge options
        this.tableselect_info = document.getElementById(this.options.tableselect_info_id);
        this.checkall = document.getElementById(this.options.checkall_id);
        this.saved_selection = document.getElementById(this.options.saved_selection_id);
        this.select_action_on = document.getElementById(this.options.select_action_on_id);
        this.select_action = document.getElementById(this.options.select_action_id);
        this.select_action_submit = document.getElementById(this.options.select_action_submit_id);
        this.checkboxes = document.querySelectorAll(this.options.checkboxes_selector);
        this.selection = [];

        if(this.saved_selection) {
            this.loadSavedSelection();
        }

        var onCbClicked = this.onCbClicked.bind(this);
        this.checkboxes.forEach(function(cb){
            cb.addEventListener('click', onCbClicked);
        });


        if(this.checkall) {
            this.checkall.addEventListener('change',function(){
                var checked = this.checkall.checked;
                this.checkboxes.forEach(function(el){
                    el.checked = checked;
                });
                return true;
            }.bind(this));
        }

        var saveSelection = this.saveSelection.bind(this);
        document.querySelectorAll(this.options.pager_selector).forEach(function(a){
            a.addEventListener('click', saveSelection);
        });

        var checkActions = this.checkActions.bind(this);
        if(this.select_action_on) {
            this.select_action_on.addEventListener('change', checkActions);
        }
        if(this.select_action) {
            this.select_action.addEventListener('change', checkActions);
        }

        this.updateInfo();
        this.checkActions();
    }

    disableSubmitAfterSubmit() {
        if (!this.select_action_submit.disabled) {
            var form = this.select_action_submit.closest('form');
            form.addEventListener('submit', function() {
                this.select_action_submit.disabled = true;
                form.submit();
            }.bind(this));
        }
    }

    updateInfo() {
        if(this.tableselect_info) {
            var nr = this.selection_all ? this.options.total - this.selection.length + 1 : this.selection.length; //+1, want all zit ook in this.selection
            this.tableselect_info.innerHTML = nr <= 0 ? '' : '<span>' + nr + ' ' + (nr > 1 ? this.options.plural : this.options.singular) + '</span>';
        }
    }

    checkActions() {
        if(this.select_action_on) {
            if (this.select_action_on.value == '' && this.selection.length > 0) {
                this.select_action_on.value = 'selection';
            }
            this.select_action_submit.disabled = this.select_action_on.value == '' || this.select_action.value == '' || (this.select_action_on.value == 'selection' && this.selection.length == 0);
            this.disableSubmitAfterSubmit();
        }
    }

    onCbClicked(evt) {
        if(evt.target.value == 'all') {
            if(evt.target.checked) {
                this.selection_all = true;
                this.selection = [];
                this.selection.push('all');
            } else {
                this.selection_all = false;
                this.selection = [];
            }
        } else {
            //Save all checked checkboxes, if selection_all is checked, we want to save all unchecked checkboxes
            if(evt.target.checked != this.selection_all) {
                this.selection.push(evt.target.value);
            } else {
                this.selection.splice(this.selection.indexOf(evt.target.value), 1);
            }
        }
        if(this.saved_selection) {
            this.saved_selection.value = this.selection.join(',');
        }
        this.updateInfo();
        this.checkActions();
    }

    saveSelection() {
        var joinedselection = this.selection.join(',');
        this.saved_selection.value = joinedselection;
        Cookie.write(this.options.cookie_name, joinedselection);
    }

    loadSavedSelection() {
        var cookieval = Cookie.read(this.options.cookie_name);
        this.saved_selection.value = cookieval;
        Cookie.write(this.options.cookie_name,'');
        if(typeof cookieval == 'string' && cookieval != '')
        {
            this.saved_selectionvalue = cookieval;
            this.selection = cookieval.split(',');
            if(this.selection[0] == 'all')
            {
                this.selection_all = true;
                this.checkall.checked = true;
            }

            this.checkboxes.forEach(function(cb){
                if (this.selection_all != this.selection.includes(cb.value)) {
                    cb.checked = true;
                }
            }.bind(this));
        }
    }
};

const selectUsersByGroup = function() {
    const selectUsersByGroup = j$('.selectusersbygroup');
    if (selectUsersByGroup.length < 1) {
        return;
    }
    selectUsersByGroup.click(function(e) {
        e.preventDefault();
        // toggle checkmark to x and vice versa.
        j$(this).find('span').toggleClass('fa-check fa-times opt-icongreen opt-iconlightgray');

        // get all ids from elements containing the checkmark.
        let ids = '';
        j$(this).closest('.nolist').find('.fa-check').each(function () {
            ids = ids + j$(this).parent().data('userids') + ',';
        });

        // select items in select element
        j$('#sel_users').select2().val(ids.split(',')).trigger("change");
    });
};

const selectAllUsers = function() {
    const selectAll = j$('#selectall, .select2ids');
    if (selectAll.length < 1) {
        return;
    }
    selectAll.click(function(e) {
        e.preventDefault();
        const selectElements = j$('#sel_users, #sel_questionnaires');
        if(j$(this).find('span').hasClass('fa-check')) {
            selectElements.select2().val('').trigger("change");
        } else {
            selectElements.find('optgroup > option').prop("selected",true);
            selectElements.select2().trigger("change");
        }

        j$(this).find('span').toggleClass('fa-check fa-times opt-icongreen opt-iconlightgray');
    });
};

window.addEventListener('DOMContentLoaded', function () {
    selectUsersByGroup();
    selectAllUsers();
});

// mod_vacature
j$(document).on('click', '.delete-confirm-js', function (e) {
    e.preventDefault();
    if (confirm($I18N.t('Are you sure the candidate is no longer in discussion about his application?'))) {
        new MpRequest(this.href, function () {
            window.location.reload(true);
        }).get();
    }
});


j$(function() {
    j$(document).on('click', '.deelnemerpool_links a', function(e){
        e.preventDefault();
        var container = j$(this).parent('.deelnemerpool_links');
        // todo: translate
        container.html("<img src='/resources/images/styles/ajax.gif' width='14' height='14' alt='Bezig met laden...' style='vertical-align:middle' /> Bezig met laden...");
        new MpRequest(this.getAttribute('href'), {'r': Math.round(Math.random()*1000)},function (jsonObj) {
            if(jsonObj.reloadwithoutjson) {
                location.href = jsonObj.url;
            } else {
                container.html(jsonObj.html);
            }
        }).get();
    });
});


// Compmatcher module
// /traject/beheerder/compmatcher
window.addEventListener('DOMContentLoaded', function() {
    j$('.mod_modcompmatcher .lightbox input').on('change',function(e){
        var levels = [];
        var comps = [];
        document.querySelectorAll('#levels input:checked').forEach(function(el){
            levels.push(el.value);
        });
        document.querySelectorAll('#comps input:checked').forEach(function(el){
            comps.push(el.value);
        });
        // todo: translate
        j$('#compressidebar').html("<img src='/resources/images/styles/ajax.gif' width='16' height='16' alt='Bezig met laden...' style='vertical-align:middle' /> Bezig met laden...");
        j$('#compresdata').html("<img src='/resources/images/styles/ajax.gif' width='16' height='16' alt='Bezig met laden...' style='vertical-align:middle' /> Bezig met laden...");
        var url = j$('#matchform').attr('action') + '/matches';
        new MpRequest(url, function(responseJSON){
            j$('#compressidebar').html(responseJSON.sidebar);
            j$('#compresdata').html(responseJSON.data);
        }).post();
    });
});

j$(document).on('click', '.mod_modcompmatcher h4.resultblock', function(e){
    // link in h4.resultblock clicked
    if (e.target.parentNode.getAttribute('href')) {
        return;
    }

    var toggler = j$(this).attr('data-target');
    var target = document.getElementById(toggler);
    var isVisible = target.style.overflow == 'visible';

    var icon = document.getElementById(toggler + '_icon');
    icon.classList.toggle('fa-plus', isVisible);
    icon.classList.toggle('fa-minus', !isVisible);
    if (target.innerHTML.length > 0) {
        if (isVisible) {
            slideUp(target);
        } else {
            slideDown(target);
        }
        return;
    }

    var url = j$('#matchform').attr('action') + '/kd/' + toggler.split('-')[1];
    j$.post(url, function(data) {
        target.innerHTML = data.html;
        slideDown(target);
    });
});


// insert template buttons
j$(function() {
    j$('.js-template-button').on('click', function(e) {
        var textarea = smartNext(j$(this), 'textarea');
        if (tinymce.editors[textarea.id]) {
            // fill tinymce
            tinymce.editors[textarea.id].execCommand('mceSetContent', false, j$(this).data('prefill'));
            return;
        }

        // fill textarea
        textarea.val(j$(this).data('prefill'));
    });
});

// portfolioshowcase
// /traject/deelnemer/showcase/{id}
window.addEventListener('DOMContentLoaded', function() {
    j$('.showcase_profile_select input.work-radio').change(function () {
        j$(this).closest('.odd, .even').find('.projects').toggleClass('h0', this.value === 'no');
    });
    j$('.showcase_profile_select input.work-radio:checked').trigger('change');

    j$('#imgtmbuploaded').change(function () {
        j$('#layout-backgroundimageupload').toggleClass('h0', !this.checked);
    });
    j$('#imgtmbuploaded').trigger('change');

    MpClick('#show_preview', function (el) {
        var oldValue = el.value;
        el.disabled = true;
        // todo: translate
        el.value = 'Voorbeeld aan het genereren...';
        new MpRequest(el.form.action + '?saveforpreview=1', function (html) {
            el.disabled = false;
            el.value = oldValue;
            if (html === 'saved') {
                document.getElementById('show_preview-link').click();
            } else {
                alert(html);
            }
        }).htmlResponse().post();
    });
});

// /manage/invoice/projectinvoiceedit/{id}
// toggle 'factuurregels' -> 'betaling'
MpChange('.calcprice-js', function (e) {
    ['default','customfixed','customoffset'].forEach(function(fieldtype) {
        var name = e.target.name.replace('calcprice_','') + '_' + fieldtype;
        var target = document.getElementById(name);
        if (target) {
            slideToggle(target, e.target.value === fieldtype);
        }
    });
});

// presentation module
// /traject/begeleider/presentation/play/{id}
window.addEventListener('DOMContentLoaded', function() {
    var slides = document.querySelectorAll('.presentation_slider section');
    if (!slides.length) {
        return;
    }

    var tabIndex = 0;
    slides.classList.add('active');

    function loadSlide(index) {
        // out of bounds or same slide
        if (index < 0 || index >= j$('.tabs .tab').length) return;
        if (index === tabIndex) return;

        // update index / remember for promise
        var oldIndex = tabIndex;
        tabIndex = index;

        // update active tab
        j$('.tabs .tab').removeClass('active');
        j$('.tabs .tab').eq(index).addClass('active');
        // update nav buttons
        j$('.nav-back-js').toggle(index !== 0);
        j$('.nav-next-js').toggle(index !== j$('.tabs .tab').length - 1);

        // update active slide
        j$('.presentation_slider section').eq(index).addClass('active');
        var width = index > oldIndex ? j$('body').width() : 0;
        j$( ".presentation_slider" ).animate({scrollLeft: width}, 100).delay(800).promise()
            .then(function() {
                j$('.presentation_slider section').eq(oldIndex).removeClass('active');
            });
    }

    // arrow keys
    window.addEventListener('keydown', (e) => {
        if (e.code === 'ArrowLeft') {
            loadSlide(tabIndex-1);
        } else if (e.code === 'ArrowRight') {
            loadSlide(tabIndex+1);
        }
    });
    // load previous
    document.querySelector('.nav-back-js').addEventListener('click', () => loadSlide(tabIndex-1));
    // load next
    document.querySelector('.nav-next-js').addEventListener('click', () => loadSlide(tabIndex+1));
    // load slide from tab
    j$('.tabs .tab').on('click', function() {
        loadSlide(j$(this).index());
    });

    // fullscreen mode
    document.querySelector('.show-fullscreen-js').addEventListener('click', function(e) {
        e.preventDefault();
        if (el.requestFullscreen) {
            el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) { /* Safari */
            el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { /* IE11 */
            el.msRequestFullscreen();
        }
    });
});

// in a portfolio: on 'interesse' click.
// /traject/deelnemer/vacaturepool
MpClick('.mod_portfoliovacaturepool .ajaxtoggle', function (el) {
    new MpRequest(el.href, function (data) {
        el.parentNode.innerHTML = data.icons;
    }).get();
});


// toggle all unchecked elements
// /traject/beheerder/module/rules/{id}
MpClick('.toggle-hidden', function (el) {
    var targetEls = j$('#' + el.dataset.target + ' div.checkboxrow:has(input[type="checkbox"]:not(:checked))');
    targetEls.toggleClass('dshow').toggleClass('dnone');
});


// Toggles the current password box in a form only when required. (when credentials are updated)
document.addEventListener('DOMContentLoaded', function (){
    document.querySelectorAll('.password-change-js').forEach(function (el) {
        el.addEventListener('input', function () {
            el.classList.toggle('changed', el.value !== el.dataset.originalValue);
            toggleVerifyPasswordBox();
        });
        el.classList.toggle('changed', el.value !== el.dataset.originalValue);
    });
    toggleVerifyPasswordBox();
});

export function toggleVerifyPasswordBox() {
    var target = document.querySelector('.current_password_box');
    if (document.querySelectorAll('.password-change-js.changed').length > 0) {
        slideDown(target);
    } else {
        slideUp(target);
    }
}
