import $ from "jquery";
import select2 from "select2";
select2($);
import "tooltipster";

// Add various jQuery vars to various global scopes for legacy support
globalThis.$ = $;
globalThis.jQuery = $;
globalThis.j$ = $.noConflict();
window.$ = $;
window.jQuery = $;
window.j$ = $.noConflict();

export default $;